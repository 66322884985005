import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { sendPasswordResetLink } from "../store/actions/auth";
import { useLocation, useHistory } from "react-router-dom";
import AlertBox from "../components/template/AlertBox";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ForgetPassword = ({ auth, login, alert, sendPasswordResetLink }) => {
  let query = useQuery();
  let history = useHistory();
  const [BuyNow, setBuyNow] = useState(query.get("buy"));
  console.log("Buy Now", BuyNow);
  return (
    <div>
      <Header />
      <div className="signin-form-area mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="form-wrap wrap-two my-5">
                <div className="signin-top d-flex justify-content-between align-items-center">
                  <h2 className="signin-title">Forget Password?</h2>
                </div>
                <div className="signin-form">
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string().required("Required"),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      console.log("Submitted");

                      await sendPasswordResetLink(values.email);
                      setSubmitting(false);
                      resetForm();
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => {
                      console.log("Submitting", isSubmitting);
                      return (
                        <Form>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Enter Email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <i className="icon fas fa-envelope" />
                          </div>
                          {errors.email && touched.email && (
                            <p className="text-danger"> {errors.email} </p>
                          )}

                          <p></p>
                          <button
                            type="submit"
                            className="submit-btn"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Processing" : "Send Reset Link"}
                          </button>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <AlertBox />
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth, alert: state.alert });

const mapDispatchToProps = { sendPasswordResetLink };

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Footer from '../components/template/Footer';
import Header from '../components/template/Header';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { addNewsletter } from '../store/actions/newsletters_action';
import AlertBox from '../components/template/AlertBox';
const Home = ({ auth: { isAuthenticated }, alert, addNewsletter }) => {
  return (
    <div>
      <div>
        <Header />
        {/* header area end here */}
        {/* hero banner area start here */}
        <div>
          <div className='hero-banner-area hero-banner-one'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-7 align-self-center'>
                  <div
                    className='banner-text wow fadeInLeft'
                    style={{
                      visibility: 'visible',
                      animationName: 'fadeInLeft',
                    }}
                  >
                    <h1 className='banner-title'>
                      <span style={{ color: '#0b2c84' }}>Total PC Scan </span>{' '}
                      takes the Guess-work Out of Upgrading Your PC
                    </h1>
                    <p className='banner-content'>
                      Total PC Scan is the best tool to easily re-establish your
                      computer in the same way it was when it was new. You can
                      keep your PC clean as it scans your whole computer to wash
                      up junk files, speed up your PC, and enhance its
                      performance.
                    </p>
                    <ul className='banner-btn'>
                      <li>
                        <Link className='primary-btn' to='/try-now'>
                          Try Now
                        </Link>
                      </li>
                      <li>
                        <Link to='/buy-now' className='primary-btn im'>
                          Buy Now
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-5 d-lg-inline-block'>
                  <div
                    className='banner-image wow fadeInRight'
                    style={{
                      visibility: 'visible',
                      animationName: 'fadeInRight',
                    }}
                  >
                    <img
                      src='assets/images/hero-banner3.jpg'
                      alt='hero banner'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* hero banner area ends here */}
          {/* service area start here */}
          <section className='service-area pd-bottom-100'>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-7'>
                  <div
                    className='section-title-area text-center wow fadeInUp'
                    style={{ visibility: 'visible', animationName: 'fadeInUp' }}
                  >
                    <span className='section-sub-title'>Our Services</span>
                    <h2 className='section-title'>
                      What Service We are Providing?
                    </h2>
                    <p className='section-text'>
                      The speed and productivity of working on a brand new PC or
                      laptop is great, the start-up procedure works with what
                      seems to be lightning speed, and the requests of programs
                      and action are almost instant, similarly installing new
                      programs is a breeze. But on the other hand, after
                      sometime, these same functions start to slow down and it
                      turns to a frustrating endeavor and waste of precious
                      time. Therefore, there is one solution that can solve all
                      such issues i.e. <strong> Total PC Scan.</strong>
                    </p>
                    <p>
                      Total PC Scan knows exactly where to seek out the useless
                      files and which of them are safe to get rid of. All you
                      need to do is a couple of clicks and you’re back on a
                      quick.
                    </p>
                  </div>
                </div>
              </div>
              <div className='row justify-content-center'>
                <div className='col-lg-4 col-md-6'>
                  <div
                    className='single-service wow zoomIn'
                    style={{ visibility: 'visible', animationName: 'zoomIn' }}
                  >
                    <div className='service-icon d-flex align-items-center justify-content-center'>
                      <img
                        src='assets/images/service/Icon1.svg'
                        alt='icon'
                        className='icon'
                      />
                    </div>
                    <h3 className='service-title'>
                      <a href='#services'>
                        Total Junk Clean Out/Free Up Valuable Disk Space
                      </a>
                    </h3>
                    <p className='service-content'>
                      Total PC Scan searches your hard drive for junk files and
                      permanently removes them therefore, freeing up memory for
                      your computer to run more effectively.
                    </p>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <div
                    className='single-service style-two wow zoomIn'
                    data-wow-delay='0.2s'
                    style={{
                      visibility: 'visible',
                      animationDelay: '0.2s',
                      animationName: 'zoomIn',
                    }}
                  >
                    <div className='service-icon d-flex align-items-center justify-content-center'>
                      <img
                        src='assets/images/service/Icon1.svg'
                        alt='icon'
                        className='icon'
                      />
                    </div>
                    <h3 className='service-title'>
                      <a href='#services'>
                        Boost Windows® Startup & Shutdown Times
                      </a>
                    </h3>
                    <p className='service-content'>
                      Total PC Scan allows you to easily control the programs
                      that launch when Windows® first starts; ensuring quick
                      load time and enhancing overall system stability.
                    </p>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6'>
                  <div
                    className='single-service style-three wow zoomIn'
                    data-wow-delay='0.4s'
                    style={{
                      visibility: 'visible',
                      animationDelay: '0.4s',
                      animationName: 'zoomIn',
                    }}
                  >
                    <div className='service-icon d-flex align-items-center justify-content-center'>
                      <img
                        src='assets/images/service/Icon1.svg'
                        alt='icon'
                        className='icon'
                      />
                    </div>
                    <h3 className='service-title'>
                      <a href='#services'>Fix Windows® Registry Corruption</a>
                    </h3>
                    <p className='service-content'>
                      Total PC Scan automatically scans the Windows® registry
                      and rectifies invalid entries and errors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* service area end here */}
          <section className='brand-success-area section'>
            <div className='container'>
              <div className='brand-success-wrap'>
                <div className='brand-success-top'>
                  <div className='row'>
                    <div className='col-lg-6 align-self-center order-1 order-lg-0'>
                      <div className='success-top-left'>
                        <h2>
                          Clean Your Computer On Right Time & In The Right Way!
                        </h2>
                        <p>
                          The right time to perform a major clean up on your PC
                          or laptop is when: • It’s taking a great amount of
                          time to boot-up • Your computer is running slower than
                          usual • You are getting error messages
                        </p>
                        <p>
                          All these problems point to the Windows® operating
                          system getting overburdened or bogged down due to
                          things like corrupt and deleted registry files,
                          invalid system references, file bloat and more.
                          However, these issues are common; they actually slow
                          down the performance of your computer. Some of the
                          causes of these problems include:
                        </p>
                        <ul className='feature-list'>
                          <li>
                            <img
                              className='icon'
                              src='assets/images/icons/m1.svg'
                              alt='feature'
                            />
                            Reduced storage capacity due to ‘file bloat’
                          </li>
                          <li>
                            <img
                              className='icon'
                              src='assets/images/icons/m2.svg'
                              alt='feature'
                            />
                            DLL issues
                          </li>
                          <li>
                            <img
                              className='icon'
                              src='assets/images/icons/m3.svg'
                              alt='feature'
                            />
                            Competing applications
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-lg-6 order-0 order-lg-1'>
                      <div
                        className='success-top-right wow fadeInRight'
                        style={{
                          visibility: 'visible',
                          animationName: 'fadeInRight',
                        }}
                      >
                        <img
                          src='assets/images/brand-success1.webp'
                          alt='brand-success'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='brand-success-bottom'>
                  <div className='row'>
                    <div className='col-lg-6 align-self-center'>
                      <div
                        className='success-bottom-left wow fadeInLeft'
                        style={{
                          visibility: 'visible',
                          animationName: 'fadeInLeft',
                        }}
                      >
                        <img
                          src='assets/images/brand-success2.webp'
                          alt='brand-success'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6 align-self-center'>
                      <div className='success-bottom-right'>
                        <div className='sigle-feature'>
                          <div className='media'>
                            <div className='icon mr-4'>+</div>
                            <div className='media-body'>
                              <h3 className='feature-title mt-0'>
                                Get Windows Registry clutter out of the way
                              </h3>
                              <p className='feature-content'>
                                Imagine having to seek out something during a
                                closet packed filled with trash. Could take you
                                hours.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='sigle-feature'>
                          <div className='media'>
                            <div className='icon mr-4'>+</div>
                            <div className='media-body'>
                              <h3 className='feature-title mt-0'>
                                Windows startup won’t slow you down again
                              </h3>
                              <p className='feature-content'>
                                Waiting forever for your PC to boot? That’s due
                                to autorun programs that load along side
                                Windows.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='sigle-feature'>
                          <div className='media'>
                            <div className='icon mr-4'>+</div>
                            <div className='media-body'>
                              <h3 className='feature-title mt-0'>
                                Uninstall the right way to keep your PC clean
                              </h3>
                              <p className='feature-content'>
                                Most Windows apps accompany their own
                                uninstallers — except they don’t always remove
                                the whole program and leave behind useless cache
                                files or toolbars.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* brand success area end here  */}
          {/* choose area start here */}
          <section className='choose-area-two section-top'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-5 align-self-center'>
                  <div className='choose-area-left'>
                    <h2>
                      Download and optimize your computer in 3 simple steps!
                    </h2>
                    <p>
                      Follow the steps mentioned below and within minutes your
                      computer will be back to its top form:
                    </p>
                    <ul>
                      <li> • Click on the "Start Download" button below</li>
                      <li>• Choose Open or Run to install PC Cleaner</li>
                      <li>
                        • Click on the Start Scan button to start – and you’re
                        done!
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-6 offset-lg-1'>
                  <div className='choose-area-right'>
                    <div className='single-choose d-flex align-items-center justify-content-center text-center'>
                      <div className='chose-wrap'>
                        <div className='icon'>
                          <img src='assets/images/icons/c1.svg' alt='icon' />
                        </div>
                        <h3>
                          <span className='counter'>2000</span> +
                        </h3>
                        <h4>Happy clients</h4>
                      </div>
                    </div>
                    <div className='single-choose d-flex align-items-center justify-content-center text-center'>
                      <div className='chose-wrap'>
                        <div className='icon'>
                          <img src='assets/images/icons/c2.svg' alt='icon' />
                        </div>
                        <h3>
                          <span className='counter'>24</span> Hour
                        </h3>
                        <h4>Technical support</h4>
                      </div>
                    </div>
                    <div className='single-choose d-flex align-items-center justify-content-center text-center'>
                      <div className='chose-wrap'>
                        <div className='icon'>
                          <img src='assets/images/icons/c3.svg' alt='icon' />
                        </div>
                        <h3>
                          <span className='counter'>70000</span> +
                        </h3>
                        <h4>Installations</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* choose area end here */}
          {/* work-progres-area  start here */}
          <section
            className='work-progres-two pd-bottom-70'
            style={{ paddingTop: 50 }}
          >
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 offset-lg-3'>
                  <div className='section-title-area text-center'>
                    {/* <span class="section-sub-title">Polls and Surveys</span> */}
                    <h2 className='section-title'>Know Our Working Process</h2>
                  </div>
                </div>
              </div>
              <div className='work-list'>
                <div className='row'>
                  <div className='col-lg-3 col-md-6'>
                    <div
                      className='single-work text-center wow zoomIn'
                      style={{ visibility: 'visible', animationName: 'zoomIn' }}
                    >
                      <div className='icon d-flex justify-content-center align-items-center'>
                        <img src='assets/images/icons/wp1.svg' alt />
                      </div>
                      <h3>Scan your PC</h3>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div
                      className='single-work text-center wow zoomIn'
                      data-wow-delay='0.2s'
                      style={{
                        visibility: 'visible',
                        animationDelay: '0.2s',
                        animationName: 'zoomIn',
                      }}
                    >
                      <div className='icon d-flex justify-content-center align-items-center'>
                        <img src='assets/images/icons/wp2.svg' alt />
                      </div>
                      <h3>Catch all your Junk files</h3>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div
                      className='single-work text-center wow zoomIn'
                      data-wow-delay='0.4s'
                      style={{
                        visibility: 'visible',
                        animationDelay: '0.4s',
                        animationName: 'zoomIn',
                      }}
                    >
                      <div className='icon d-flex justify-content-center align-items-center'>
                        <img src='assets/images/icons/wp3.svg' alt />
                      </div>
                      <h3>Clean your PC Safely</h3>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div
                      className='single-work text-center wow zoomIn'
                      data-wow-delay='0.6s'
                      style={{
                        visibility: 'visible',
                        animationDelay: '0.6s',
                        animationName: 'zoomIn',
                      }}
                    >
                      <div className='icon d-flex justify-content-center align-items-center'>
                        <img src='assets/images/icons/wp4.svg' alt />
                      </div>
                      <h3>Optimize your PC Speed</h3>
                    </div>
                  </div>
                </div>
                <div className='line-shape'>
                  <img src='assets/images/line-shape.png' alt='line-shape' />
                </div>
              </div>
            </div>
          </section>
          {/* work-progres-area  end here */}
          {/* pricing area start here */}
          <section className='pricing-area pd-top-50'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 offset-lg-3'>
                  <div
                    className='section-title-area text-center wow fadeInUp'
                    style={{ visibility: 'visible', animationName: 'fadeInUp' }}
                  >
                    <span className='section-sub-title'>Our Pricing Plan</span>
                    <h2 className='section-title'>Check Our Flexible Plan</h2>
                  </div>
                </div>
              </div>
              <div className='pricing-tab'>
                <div className='tab-content' id='nav-tabContent'>
                  <div
                    className='tab-pane fade show active'
                    id='nav-annual'
                    role='tabpanel'
                    aria-labelledby='nav-annual-tab'
                  >
                    <div className='row justify-content-center'>
                      <div className='col-lg-4 col-md-6'>
                        <div
                          className='single-pricing text-center wow zoomIn'
                          style={{
                            visibility: 'visible',
                            animationName: 'zoomIn',
                          }}
                        >
                          <div className='price-header'>
                            <div className='icon'>
                              <img
                                src='assets/images/hero-banner3.jpg'
                                alt='icon'
                              />
                            </div>
                            <h3 className='pack-name'>1 Year Subscription</h3>
                            {/* <h6 class="premium"><b>1 Year</b></h6> */}
                            <h2 className='price'>
                              <sup className='currency'>$</sup>29
                            </h2>
                          </div>
                          {/* <div class="price-body">
                <ul class="pack-info">
                  <li>Prevents threats in real-time</li>
                  <li>Crushes ransomware</li>
                  <li>Defends against harmful sites</li>
                  <li>Cleans and removes malware</li>
                </ul>
              </div> */}
                          <div className='price-footer'>
                            {isAuthenticated ? (
                              <Link
                                to='/checkout?product_id=1'
                                className='register-btn active'
                              >
                                Buy Now
                              </Link>
                            ) : (
                              <Link
                                to='/login?buy=true'
                                className='register-btn active'
                              >
                                Buy Now
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div
                          className='single-pricing text-center wow zoomIn'
                          data-wow-delay='0.2s'
                          style={{
                            visibility: 'visible',
                            animationDelay: '0.2s',
                            animationName: 'zoomIn',
                          }}
                        >
                          <div className='price-header'>
                            <div className='icon'>
                              <img src='assets/images/2-year.webp' alt='icon' />
                            </div>
                            <h3 className='pack-name'>2 Year Subscription</h3>
                            {/* <h6 class="premium"><b>2 Year</b></h6> */}
                            <h2 className='price'>
                              <sup className='currency'>$</sup>49
                            </h2>
                          </div>
                          {/* <div class="price-body">
                <ul class="pack-info">
                  <li>10 Key Words Optimized</li>
                  <li>3 Top 10 Ranking Guarantee</li>
                  <li>Web site Analysis</li>
                  <li>Keyword Research and Analysis</li>
                  <li>Content Optimization</li>
                </ul>
              </div> */}
                          <div className='price-footer'>
                            {isAuthenticated ? (
                              <Link
                                to='/checkout?product_id=2'
                                className='register-btn active'
                              >
                                Buy Now
                              </Link>
                            ) : (
                              <Link
                                to='/login?buy=true'
                                className='register-btn active'
                              >
                                Buy Now
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div
                          className='single-pricing text-center wow zoomIn'
                          data-wow-delay='0.4s'
                          style={{
                            visibility: 'visible',
                            animationDelay: '0.4s',
                            animationName: 'zoomIn',
                          }}
                        >
                          <div className='price-header'>
                            <div className='icon'>
                              <img src='assets/images/3year.webp' alt='icon' />
                            </div>
                            <h3 className='pack-name'>3 Year Subscription</h3>
                            {/* <h6 class="premium"><b>3 Year</b></h6> */}
                            <h2 className='price'>
                              <sup className='currency'>$</sup>69
                            </h2>
                          </div>
                          {/* <div class="price-body">
                <ul class="pack-info">
                  <li>10 Key Words Optimized</li>
                  <li>3 Top 10 Ranking Guarantee</li>
                  <li>Web site Analysis</li>
                  <li>Keyword Research and Analysis</li>
                  <li>Content Optimization</li>
                </ul>
              </div> */}
                          <div className='price-footer'>
                            {isAuthenticated ? (
                              <Link
                                to='/checkout?product_id=3'
                                className='register-btn active'
                              >
                                Buy Now
                              </Link>
                            ) : (
                              <Link
                                to='/login?buy=true'
                                className='register-btn active'
                              >
                                Buy Now
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade'
                    id='nav-monthly'
                    role='tabpanel'
                    aria-labelledby='nav-monthly-tab'
                  >
                    <div className='row justify-content-center'>
                      <div className='col-lg-4 col-md-6'>
                        <div
                          className='single-pricing text-center wow zoomIn'
                          style={{
                            visibility: 'visible',
                            animationName: 'zoomIn',
                          }}
                        >
                          <div className='price-header'>
                            <div className='icon'>
                              <img
                                src='assets/images/icons/p1.svg'
                                alt='icon'
                              />
                            </div>
                            <h3 className='pack-name'>Starter Pack</h3>
                            <h2 className='price'>
                              <sup className='currency'>$</sup>199
                            </h2>
                          </div>
                          <div className='price-body'>
                            <ul className='pack-info'>
                              <li>10 Key Words Optimized</li>
                              <li>3 Top 10 Ranking Guarantee</li>
                              <li>Web site Analysis</li>
                              <li>Keyword Research and Analysis</li>
                              <li>Content Optimization</li>
                            </ul>
                          </div>
                          <div className='price-footer'>
                            <a href='signin3.html' className='register-btn'>
                              Register Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div
                          className='single-pricing text-center wow zoomIn'
                          data-wow-delay='0.2s'
                          style={{
                            visibility: 'visible',
                            animationDelay: '0.2s',
                            animationName: 'zoomIn',
                          }}
                        >
                          <div className='price-header'>
                            <div className='icon'>
                              <img
                                src='assets/images/icons/p2.svg'
                                alt='icon'
                              />
                            </div>
                            <h3 className='pack-name'>Advance Pack</h3>
                            <h2 className='price'>
                              <sup className='currency'>$</sup>299
                            </h2>
                          </div>
                          <div className='price-body'>
                            <ul className='pack-info'>
                              <li>10 Key Words Optimized</li>
                              <li>3 Top 10 Ranking Guarantee</li>
                              <li>Web site Analysis</li>
                              <li>Keyword Research and Analysis</li>
                              <li>Content Optimization</li>
                            </ul>
                          </div>
                          <div className='price-footer'>
                            <a
                              href='signin3.html'
                              className='register-btn active'
                            >
                              Register Now
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6'>
                        <div
                          className='single-pricing text-center wow zoomIn'
                          data-wow-delay='0.4s'
                          style={{
                            visibility: 'visible',
                            animationDelay: '0.4s',
                            animationName: 'zoomIn',
                          }}
                        >
                          <div className='price-header'>
                            <div className='icon'>
                              <img
                                src='assets/images/icons/p3.svg'
                                alt='icon'
                              />
                            </div>
                            <h3 className='pack-name'>Premium Pack</h3>
                            <h2 className='price'>
                              <sup className='currency'>$</sup>399
                            </h2>
                          </div>
                          <div className='price-body'>
                            <ul className='pack-info'>
                              <li>10 Key Words Optimized</li>
                              <li>3 Top 10 Ranking Guarantee</li>
                              <li>Web site Analysis</li>
                              <li>Keyword Research and Analysis</li>
                              <li>Content Optimization</li>
                            </ul>
                          </div>
                          <div className='price-footer'>
                            <a href='#' className='register-btn'>
                              Register Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* pricing area end here */}
          {/* testimonial area start here */}

          {/* Subsribe area start here */}
          <div className='subsribe-area section-top'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div
                    className='subsribe-wrap text-center wow fadeInUp'
                    style={{ visibility: 'visible', animationName: 'fadeInUp' }}
                  >
                    <h2>
                      Interested To <br />
                      Get Our Featured Service
                    </h2>
                    <div className='row justify-content-center'>
                      <div className='col-lg-10'>
                        <Formik
                          initialValues={{
                            email: '',
                          }}
                          validationSchema={Yup.object({
                            email: Yup.string().email().required('Required'),
                          })}
                          onSubmit={async (
                            values,
                            { setSubmitting, resetForm }
                          ) => {
                            console.log('Submitted');

                            await addNewsletter({
                              email: values.email,
                            });
                            setSubmitting(false);
                            resetForm();
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                          }) => {
                            console.log('Submitting', isSubmitting);
                            return (
                              <Form>
                                <div className='form-group'>
                                  <input
                                    type='email'
                                    className='form-control'
                                    name='email'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    placeholder='Enter Your Email'
                                  />
                                  <button
                                    type='submit'
                                    className='subsribe-btn'
                                    disabled={isSubmitting}
                                  >
                                    {isSubmitting
                                      ? 'Processing'
                                      : 'Subsribe Now'}
                                  </button>
                                  {errors.email && touched.email && (
                                    <p className='text-danger'>
                                      {' '}
                                      {errors.email}{' '}
                                    </p>
                                  )}
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AlertBox />
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth, alert: state.alert });

const mapDispatchToProps = { addNewsletter };

export default connect(mapStateToProps, mapDispatchToProps)(Home);

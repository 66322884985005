import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import moment from "moment";
import { useLocation } from "react-router-dom";

import { PayPalButton } from "react-paypal-button-v2";
import docs from "../shared/enums/products.json";
import { addOrder } from "../store/actions/orders_action";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const Checkout = ({
  addOrder,
  auth: { loading, isAuthenticated, user },
}) => {
  let query = useQuery();
  const [product_id, setProduct_id] = useState(query.get("product_id"));
  const [amount, setAmount] = useState(null);
  const [validity, setvalidity] = useState(null);
  const [product, setProduct] = useState(null);
  useEffect(() => {
    console.log(docs);
    console.log(product_id);
    const filterId = docs.filter((item) => item.id == product_id);
    console.log(filterId);
    if (filterId.length > 0) {
      setAmount(filterId[0].amount);
      var fm = moment().add(filterId[0].years, "Y");
      setvalidity(fm.format("YYYY-MM-DD"));
    }
  }, [docs, product_id]);
  useEffect(() => {
    const filterId = docs.filter((item) => item.id == product_id);
    setProduct(filterId);
  }, [product_id]);

  return (
    <div>
      <Header />
      <section
        className="pricing-area pd-top-50 ptt"
        style={{ paddingTop: 200 }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div>
                <div style={{ background: "#f1f1f1" }} className="mb-3">
                  <div style={{ padding: "10px 10px" }}>
                    {product &&
                      product.map((item) => {
                        return (
                          <div style={{ fontSize: "18px", fontWeight: 600 }}>
                            {item.name}
                          </div>
                        );
                      })}

                    <div> Clean up and optimise your PC in a few clicks! </div>
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: "20px",
                        fontWeight: 600,
                      }}
                    >
                      You are paying ${amount}{" "}
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <PayPalButton
                    amount={amount}
                    // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                    onSuccess={async (details, data) => {
                      return await addOrder({
                        amount: amount,
                        purchased_date: moment().format("YYYY-MM-DD"),
                        validity: validity,
                        user: user.uid,
                      });
                    }}
                    options={{
                      clientId:
                        "AbeYybE7sc6t2boEUcs3MVQFCTA7Abd-WT69MdGteMY9GIXSo4cAFjsdHtGwMdwlLalRSrMkiyOuVz4i",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div
                style={{ border: "1px solid #f1f1f1", padding: "10px 10px" }}
              >
                <img src="/assets/images/guarantee-en.png" />
                <p
                  style={{
                    borderBottom: "1px solid #f1f1f1",
                    paddingTop: "10px",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  100% SECURE TRANSACTION{" "}
                </p>
                <p>
                  We use 2048-bit SSL encryption technology to secure your
                  transactions.
                </p>
                <p
                  style={{
                    borderBottom: "1px solid #f1f1f1",
                    paddingTop: "10px",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  INSTANT AND SECURED DOWNLOAD
                </p>
                <p>
                  Get instant satisfaction and start using your software
                  immediately.
                </p>
                <img
                  src="/assets/images/icon-cc.png"
                  style={{ paddingBottom: "20px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth, order: state.order });

const mapDispatchToProps = { addOrder };

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);

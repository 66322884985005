import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Footer from '../components/template/Footer';
import Header from '../components/template/Header';

export const BuyNow = ({ auth: { loading, isAuthenticated, user, trial } }) => {
  return (
    <div>
      <Header />
      <section className='banner-shop'>
        <img src='/assets/images/scan-banner.webp' style={{ width: '100%' }} />
        <div className='btn-wrapper'>
          <div>
            <a href='#buynow' className='buy-now'>
              {' '}
              Buy Now{' '}
            </a>
          </div>
          <div>
            <Link to='/try-now' className='try-now'>
              Try for 15 days free{' '}
            </Link>
          </div>
        </div>
      </section>
      <section
        className='pricing-area pd-top-50 ptt'
        style={{ paddingTop: 200 }}
      >
        <div className='container' id='buynow'>
          <div className='row'>
            <div className='col-lg-6 offset-lg-3'>
              <div
                className='section-title-area text-center wow fadeInUp'
                style={{ visibility: 'visible', animationName: 'fadeInUp' }}
              >
                <span className='section-sub-title'>Our Pricing Plan</span>
                <h2 className='section-title'>Check Our Flexible Plan</h2>
              </div>
            </div>
          </div>

          <div className='pricing-tab'>
            <div className='tab-content' id='nav-tabContent'>
              <div
                className='tab-pane fade show active'
                id='nav-annual'
                role='tabpanel'
                aria-labelledby='nav-annual-tab'
              >
                <div className='row justify-content-center'>
                  <div className='col-lg-4 col-md-6'>
                    <div
                      className='single-pricing text-center wow zoomIn'
                      style={{ visibility: 'visible', animationName: 'zoomIn' }}
                    >
                      <div className='price-header'>
                        <div className='icon'>
                          <img
                            src='assets/images/hero-banner3.jpg'
                            alt='icon'
                          />
                        </div>
                        <h3 className='pack-name'>1 Year Subscription</h3>
                        {/* <h6 class="premium"><b>1 Year</b></h6> */}
                        <h2 className='price'>
                          <sup className='currency'>$</sup>29
                        </h2>
                      </div>
                      {/* <div class="price-body">
                  <ul class="pack-info">
                    <li>Prevents threats in real-time</li>
                    <li>Crushes ransomware</li>
                    <li>Defends against harmful sites</li>
                    <li>Cleans and removes malware</li>
                  </ul>
                </div> */}
                      <div className='price-footer'>
                        {isAuthenticated ? (
                          <Link
                            to='/checkout?product_id=1'
                            className='register-btn active'
                          >
                            Buy Now
                          </Link>
                        ) : (
                          <Link
                            to='/login?buy=true'
                            className='register-btn active'
                          >
                            Buy Now
                          </Link>
                        )}
                      </div>
                      <table className='table table-striped mt-3'>
                        <tbody>
                          <tr>
                            <td>Analyse and fix the problems with your PC</td>
                          </tr>
                          <tr>
                            <td>
                              Detect errors, invalid entries and obsolete
                              registry data{' '}
                            </td>
                          </tr>
                          <tr>
                            <td>Clean junk files and confidential data</td>
                          </tr>
                          <tr>
                            <td>
                              Manages Windows start-up and boosts start-up time{' '}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Performs a clean uninstall of unwanted programs,
                              extensions and toolbars
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Keep your PC clean and performing at its best
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className='price-footer'>
                        {isAuthenticated ? (
                          <Link
                            to='/checkout?product_id=1'
                            className='register-btn active'
                          >
                            Buy Now
                          </Link>
                        ) : (
                          <Link
                            to='/login?buy=true'
                            className='register-btn active'
                          >
                            Buy Now
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <div
                      className='single-pricing text-center wow zoomIn'
                      data-wow-delay='0.2s'
                      style={{
                        visibility: 'visible',
                        animationDelay: '0.2s',
                        animationName: 'zoomIn',
                      }}
                    >
                      <div className='price-header'>
                        <div className='icon'>
                          <img src='assets/images/2-year.webp' alt='icon' />
                        </div>
                        <h3 className='pack-name'>2 Year Subscription</h3>
                        {/* <h6 class="premium"><b>2 Year</b></h6> */}
                        <h2 className='price'>
                          <sup className='currency'>$</sup>49
                        </h2>
                      </div>
                      {/* <div class="price-body">
                  <ul class="pack-info">
                    <li>10 Key Words Optimized</li>
                    <li>3 Top 10 Ranking Guarantee</li>
                    <li>Web site Analysis</li>
                    <li>Keyword Research and Analysis</li>
                    <li>Content Optimization</li>
                  </ul>
                </div> */}
                      <div className='price-footer'>
                        {isAuthenticated ? (
                          <Link
                            to='/checkout?product_id=2'
                            className='register-btn active'
                          >
                            Buy Now
                          </Link>
                        ) : (
                          <Link
                            to='/login?buy=true'
                            className='register-btn active'
                          >
                            Buy Now
                          </Link>
                        )}
                      </div>
                      <table className='table table-striped mt-3'>
                        <tbody>
                          <tr>
                            <td>Analyse and fix the problems with your PC</td>
                          </tr>
                          <tr>
                            <td>
                              Detect errors, invalid entries and obsolete
                              registry data{' '}
                            </td>
                          </tr>
                          <tr>
                            <td>Clean junk files and confidential data</td>
                          </tr>
                          <tr>
                            <td>
                              Manages Windows start-up and boosts start-up time{' '}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Performs a clean uninstall of unwanted programs,
                              extensions and toolbars
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Keep your PC clean and performing at its best
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className='price-footer'>
                        {isAuthenticated ? (
                          <Link
                            to='/checkout?product_id=2'
                            className='register-btn active'
                          >
                            Buy Now
                          </Link>
                        ) : (
                          <Link
                            to='/login?buy=true'
                            className='register-btn active'
                          >
                            Buy Now
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <div
                      className='single-pricing text-center wow zoomIn'
                      data-wow-delay='0.4s'
                      style={{
                        visibility: 'visible',
                        animationDelay: '0.4s',
                        animationName: 'zoomIn',
                      }}
                    >
                      <div className='price-header'>
                        <div className='icon'>
                          <img src='assets/images/3year.webp' alt='icon' />
                        </div>
                        <h3 className='pack-name'>3 Year Subscription</h3>
                        {/* <h6 class="premium"><b>3 Year</b></h6> */}
                        <h2 className='price'>
                          <sup className='currency'>$</sup>69
                        </h2>
                      </div>
                      {/* <div class="price-body">
                  <ul class="pack-info">
                    <li>10 Key Words Optimized</li>
                    <li>3 Top 10 Ranking Guarantee</li>
                    <li>Web site Analysis</li>
                    <li>Keyword Research and Analysis</li>
                    <li>Content Optimization</li>
                  </ul>
                </div> */}
                      <div className='price-footer'>
                        {isAuthenticated ? (
                          <Link
                            to='/checkout?product_id=3'
                            className='register-btn active'
                          >
                            Buy Now
                          </Link>
                        ) : (
                          <Link
                            to='/login?buy=true'
                            className='register-btn active'
                          >
                            Buy Now
                          </Link>
                        )}
                      </div>
                      <table className='table table-striped mt-3'>
                        <tbody>
                          <tr>
                            <td>Analyse and fix the problems with your PC</td>
                          </tr>
                          <tr>
                            <td>
                              Detect errors, invalid entries and obsolete
                              registry data{' '}
                            </td>
                          </tr>
                          <tr>
                            <td>Clean junk files and confidential data</td>
                          </tr>
                          <tr>
                            <td>
                              Manages Windows start-up and boosts start-up time{' '}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Performs a clean uninstall of unwanted programs,
                              extensions and toolbars
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Keep your PC clean and performing at its best
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className='price-footer'>
                        {isAuthenticated ? (
                          <Link
                            to='/checkout?product_id=3'
                            className='register-btn active'
                          >
                            Buy Now
                          </Link>
                        ) : (
                          <Link
                            to='/login?buy=true'
                            className='register-btn active'
                          >
                            Buy Now
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BuyNow);

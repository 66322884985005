import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBRPdy9tW1hktMWZdkNIYYibJ1-pq-9JPM",
  authDomain: "total-pc-clean.firebaseapp.com",
  projectId: "total-pc-clean",
  storageBucket: "total-pc-clean.appspot.com",
  messagingSenderId: "881157482715",
  appId: "1:881157482715:web:78679c338b5fb4751e84fe",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };

import React from "react";
import { connect } from "react-redux";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
const Uninstall = (props) => {
  return (
    <div>
      <Header />
      <section className="terms-area section" style={{ paddingTop: 150 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="section-title-area text-center">
                <h2 className="section-title">How to Uninstall</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="terms-wrap">
                <div className="terms-top">
                  <p>
                    If you are not fully satisfied with your software, please
                    follow the instructions below to fully uninstall it from
                    your computer.
                  </p>
                  <h2 className="terms-title">Windows 10, 8, 7 and Vista:</h2>
                  <ul>
                    <li> Click ‘Start’ (Bottom Left of your screen)</li>
                    <li>Then click ‘Control Panel’</li>
                    <li>Next click ‘Programs and Features’</li>
                    <li>
                      Find the software you wish to uninstall and click
                      ‘Uninstall’{" "}
                    </li>
                  </ul>
                  <h2 className="terms-title">Windows XP:</h2>
                  <ul>
                    <li> Click ‘Start’ (Bottom Left of your screen)</li>
                    <li>Move to ‘Settings’ then click ‘Control Panel’</li>
                    <li>Next click ‘Add or Remove Programs’</li>
                    <li>
                      Find the software you wish to uninstall and click ‘Remove’
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Uninstall);

import React from 'react';
import { connect } from 'react-redux';
import Footer from '../components/template/Footer';
import Header from '../components/template/Header';
export const PrivacyPolicy = (props) => {
  return (
    <div>
      <Header />
      <section className="terms-area section" style={{ paddingTop: 150 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="section-title-area text-center">
                <h2 className="section-title">Privacy Policy</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="terms-wrap">
                <div className="terms-top">
                  <p>
                    www.totalpcscan.com has created this policy in order to
                    demonstrate our firm commitment to your privacy. The
                    following discloses the information gathering and
                    dissemination practices for the Total PC Scan network of
                    sites.
                  </p>
                  <h2 className="terms-title">Our Privacy Guarantee</h2>
                  <p>
                    We will never sell, rent or share any of your personal
                    information with a third-party without your express prior
                    permission.
                  </p>
                  <p>
                    <strong> Personal information</strong>
                  </p>
                  <p>
                    {' '}
                    We use the personal information you provide voluntarily to
                    send information you’ve requested and to fulfil orders. The
                    specific use of your personal information varies, depending
                    on how you contact us:
                  </p>
                  <p>
                    • When you subscribe to one of our newsletters to receive
                    industry news or services or subscribe to one of our mailing
                    lists, you must provide your email address. This information
                    is solely used to provide these services to you.
                  </p>
                  <p>
                    • When you purchase software or any other services from one
                    of our websites, we will ask for additional information such
                    as name, address, and credit card information. We will use
                    this information as needed for your purchase. We will also
                    store your name and email to contact you regarding your
                    purchases or as needed in the future. All information
                    related to your credit card (credit card number and
                    expiration date) is discarded after your purchase and is not
                    stored on our servers.
                  </p>
                  <p>
                    • When you enter a contest or survey, we may ask for your
                    name, address, and email address so we can administer the
                    contest and notify winners.
                  </p>
                  <p>
                    • Any information you volunteer to us is used solely to
                    provide the specific services you requested. We do not sell
                    or rent any information provided to us to third parties
                    unless specifically noted on the form you are filling out.
                    Please note, though, that our interactive mailing lists may
                    be archived by other third parties sites and that if you
                    contribute to these mailing lists (by posting an email
                    message directly to that mailing list), your information may
                    be found in their archives.
                  </p>

                  <p>
                    {' '}
                    <strong> Log Files </strong>{' '}
                  </p>
                  <p>
                    {' '}
                    We use IP addresses to analyse trends, administer the site,
                    track user movement, and gather broad demographic
                    information for aggregate use. IP addresses are not linked
                    to personally identifiable information.
                  </p>

                  <p>
                    {' '}
                    <strong>Cookies</strong>{' '}
                  </p>
                  <p>
                    As part of our approach to providing personalized services
                    on our website, we employ the use of cookies to store and
                    sometimes track information about you. A cookie is a small
                    data file sent to your browser from a web server to be
                    stored on your hard drive that allows easier access the next
                    time the same page is visited. For example, a cookie is sent
                    when you register or sign in to this site. With your
                    consent, cookies can store information from forms you fill
                    out to create a user profile so that you do not have to
                    re-enter the information each time you visit.
                  </p>
                  <p>
                    We may also employ third party vendors, including Google to
                    show our ads on sites on the internet and to use cookies to
                    serve ads based on a user’s prior visits to our website.
                    These cookies do not store sensitive information such as
                    contact details. At any time, users may opt out of Google’s
                    use of cookies by visiting the Google advertising opt-out
                    page or the Network Advertising Initiative opt out page.
                  </p>

                  <p>
                    {' '}
                    <strong>
                      {' '}
                      From time to time, we may also use Remarketing Dynamic Ads
                      which use the Teracent cookie. Users may opt out of the
                      Teracent cookie by visiting the Teracent opt-out page.
                    </strong>
                  </p>
                  <p>
                    If you do not want your personal information to be stored by
                    cookies, you can configure your browser so that it notifies
                    you whenever a cookie is received so you can decide each
                    time if you accept them or not. However, the use of cookies
                    may be necessary to provide certain features and choosing to
                    reject cookies may reduce the functionality of the site.
                    Your browser help should include precise instructions
                    explaining how to define the acceptance of cookies.
                  </p>
                  <p>
                    Our website also automatically captures IP addresses. We use
                    IP addresses to help diagnose problems with our server, to
                    administer our website, and to help ensure the security of
                    your interaction with the website.
                  </p>

                  <p>
                    {' '}
                    <strong>Services</strong>{' '}
                  </p>
                  <p>
                    Our sites may contain many links to other sites. Total PC
                    Scan Software is not responsible for the privacy practices
                    or the content of these other Websites.
                  </p>

                  <p>
                    {' '}
                    <strong> Contests & Surveys</strong>
                  </p>
                  <p>
                    {' '}
                    We may run contests or surveys on our site in which we ask
                    our users for contact information (like their email
                    address). We use the customer contact information from the
                    entry form to send the user information about our company
                    and its products, or to contact them when it becomes
                    necessary (Notices like updates, new features, etc.). Users
                    may opt-out of receiving future mailings (see the
                    choice/opt-out section below).
                  </p>

                  <p>
                    {' '}
                    <strong> Security</strong>
                  </p>
                  <p>
                    This site has security measures in place to protect the
                    loss, misuse and alteration of the information under our
                    control. All the data regarding customers, users, their
                    computers’ system information and data are stored on a
                    server. This server is located in a locked and guarded space
                    and only authorized personnel have access to the database
                    containing the stored information.
                  </p>

                  <p>
                    {' '}
                    <strong> Uninstallation</strong>
                  </p>
                  <p>
                    You can uninstall the software application at any time
                    through Windows Control Panel. To remove the software
                    follows these steps: click on Start button, then Control
                    Panel and open Add or Remove Programs. Highlight the
                    application in the list and press Uninstall to remove it
                    from your system.
                  </p>

                  <p>
                    {' '}
                    <strong> Advertisers</strong>
                  </p>
                  <p>
                    We use an outside advertising company to display ads on our
                    site. These ads may contain cookies. While we use cookies in
                    other parts of our Web site, cookies received with banner
                    ads are collected by our ad company, and we do not have
                    access to this information.
                  </p>
                  <p>
                    Some anonymous demographic data is shared with the
                    advertising companies on an aggregate basis.
                  </p>
                  <p>
                    {' '}
                    <strong> Third Party Cookies</strong>{' '}
                  </p>
                  <p>
                    In the course of serving advertisements to this site, our
                    third-party advertiser may place or recognize a unique
                    ‘cookie’ on your browser.
                  </p>

                  <p>
                    {' '}
                    <strong> Notification of Changes</strong>{' '}
                  </p>
                  <p>
                    {' '}
                    If we change our Privacy Policy, we will post those changes
                    on our site. If we decide to use personally identifiable
                    information in a manner different from that stated at the
                    time it was collected, we will notify users via email. Users
                    will be able to opt out of any new use of their personal
                    information.
                  </p>
                  <p>
                    {' '}
                    <strong>Choice/Opt-Out </strong>
                  </p>
                  <p>
                    {' '}
                    Our site provides users the opportunity to opt-out of
                    receiving communications from us and from our partners at
                    the point where we request information about the user.
                  </p>
                  <p>
                    This site gives users the following options for removing
                    their information from our database so that they no longer
                    receive future communications or access to our other
                    services:
                  </p>
                  <p>Email to info@totalpcscan.com, stating your request.</p>
                  <p>
                    Our newsletters and mailings contain a link near the bottom
                    of each mailing that can be used to automatically
                    unsubscribe from that service.
                  </p>
                  <p>
                    Reply to any unwanted communications that you receive,
                    stating your request.
                  </p>

                  <p>
                    {' '}
                    <strong> Contacting the Website </strong>{' '}
                  </p>
                  <p>
                    {' '}
                    If you have any questions or comments about this privacy
                    statement, the practices of our sites, or your dealings with
                    any of our websites, you can contact support@totalpcscan.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);

import React from "react";
import { connect } from "react-redux";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import docs from "../shared/enums/faq.json";
const FAQ = (props) => {
  return (
    <div>
      <Header />
      <section className="faq-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div
                className="section-title-area text-center"
                style={{ paddingTop: 150 }}
              >
                <h2 className="section-title">frequently asked questions</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="accordion">
                {docs &&
                  docs.map((item) => {
                    return (
                      <div className="card">
                        <div className="card-header">
                          <button
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                          >
                            {item.question}
                          </button>
                        </div>
                        <div id="collapseOne" className="collapse show">
                          <div className="card-body">
                            <p>{item.answer}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);

import { db } from '../../shared/config';
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  startAfter,
  endBefore,
  limitToLast,
  limit,
  Timestamp,
  where,
} from 'firebase/firestore';
import {
  GET_CONTACTS,
  ADD_CONTACT,
  GET_CONTACT,
  RESET_CONTACT,
  CONTACTS_ERROR,
} from '../types/contact_type';

import { setAlert } from './alert';
import { LIMIT } from '../../domain/constant';
//GET CONTACTS
const contactsRef = collection(db, 'contacts');
export const getContacts =
  ({ term, termField, orderValue = 'createdAt', orderType = 'desc' }) =>
  async (dispatch) => {
    try {
      let searchQuery = null;
      if (term) {
        searchQuery = query(contactsRef, where(termField, '==', term));
      } else {
        searchQuery = query(
          contactsRef,
          orderBy(orderValue, orderType),
          limit(LIMIT)
        );
      }
      const querySnapshot = await getDocs(searchQuery);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: GET_CONTACTS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert('Something went wrong', 'danger'));
      dispatch({
        type: CONTACTS_ERROR,
      });
    }
  };

export const getNextContacts =
  ({ term, termField, last, orderValue = 'createdAt', orderType = 'desc' }) =>
  async (dispatch) => {
    try {
      let searchQuery = null;
      if (term) {
        searchQuery = query(
          contactsRef,
          orderBy(orderValue, orderType),
          startAfter(last[orderValue]),
          limit(LIMIT),
          where(termField, '==', term)
        );
      } else {
        searchQuery = query(
          contactsRef,
          orderBy(orderValue, orderType),
          startAfter(last[orderValue]),
          limit(LIMIT)
        );
      }
      const querySnapshot = await getDocs(searchQuery);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: GET_CONTACTS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert('Something went wrong', 'danger'));
      dispatch({
        type: CONTACTS_ERROR,
      });
    }
  };
export const getPreviousContacts =
  ({ term, termField, first, orderValue = 'createdAt', orderType = 'desc' }) =>
  async (dispatch) => {
    try {
      let searchQuery = null;
      if (term) {
        searchQuery = query(
          contactsRef,
          orderBy(orderValue, orderType),
          endBefore(first[orderValue]),
          limitToLast(LIMIT),
          where(termField, '==', term)
        );
      } else {
        searchQuery = query(
          contactsRef,
          orderBy(orderValue, orderType),
          endBefore(first[orderValue]),
          limitToLast(LIMIT)
        );
      }

      const querySnapshot = await getDocs(searchQuery);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: GET_CONTACTS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert('Something went wrong', 'danger'));
      dispatch({
        type: CONTACTS_ERROR,
      });
    }
  };

export const addContact = (data) => async (dispatch) => {
  try {
    data.createdAt = Timestamp.fromDate(new Date());
    const docRef = await addDoc(contactsRef, data);
    dispatch({
      type: ADD_CONTACT,
    });
    dispatch(
      setAlert(
        'Thank You for contacting us. We will get back to you shortly',
        'success'
      )
    );
    let headersList = {
      Accept: '*/*',
    };

    let response = await fetch(
      `https://totalpcscan.com/config/mail.php?name=${data.name}&phone=${data.phone}&email=${data.email}&message=${data.message}`,
      {
        method: 'GET',
        headers: headersList,
      }
    );

    let data_new = await response.text();
  } catch (e) {
    console.error('Error adding document: ', e);
    dispatch(setAlert('Something went wrong', 'danger'));
    dispatch({
      type: CONTACTS_ERROR,
    });
  }
};

export const getContact = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_CONTACT,
    });
    const docRef = doc(db, 'contacts', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      data.id = id;
      dispatch({
        type: GET_CONTACT,
        payload: data,
      });
    } else {
      dispatch(setAlert('Something went wrong', 'danger'));
      dispatch({
        type: CONTACTS_ERROR,
      });
    }
  } catch (err) {
    dispatch(setAlert('Something went wrong', 'danger'));
    dispatch({
      type: CONTACTS_ERROR,
    });
  }
};
export const editContact = (id, data) => async (dispatch) => {
  try {
    const docSnap = await updateDoc(doc(db, 'contacts', id), data);
    dispatch(setAlert('Contact updated successfully', 'success'));
  } catch (err) {
    dispatch(setAlert('Something went wrong', 'danger'));
    dispatch({
      type: CONTACTS_ERROR,
    });
  }
};

export const deleteContact = (id) => async (dispatch) => {
  try {
    const docSnap = await deleteDoc(doc(db, 'contacts', id));
    dispatch(setAlert('Contact deleted successfully', 'success'));
  } catch (err) {
    dispatch(setAlert('Something went wrong', 'danger'));
    dispatch({
      type: CONTACTS_ERROR,
    });
  }
};

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Footer from '../components/template/Footer';
import Header from '../components/template/Header';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useLocation, useHistory } from 'react-router-dom';
import { register } from '../store/actions/auth';
import AlertBox from '../components/template/AlertBox';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const SignUp = ({ auth, register, alert }) => {
  let query = useQuery();
  let history = useHistory();
  const [BuyNow, setBuyNow] = useState(query.get('buy'));
  return (
    <div>
      <Header />
      <div className='signin-form-area' style={{ paddingTop: 100 }}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 offset-lg-2'>
              <div className='form-wrap wrap-two my-5'>
                <div className='signin-top d-flex justify-content-between align-items-center'>
                  <h2 className='signin-title'>Sign Up</h2>
                  {BuyNow ? (
                    <Link to='/login?buy=true' className='sign-btn'>
                      Sign In
                    </Link>
                  ) : (
                    <Link to='/login' className='sign-btn'>
                      Sign In
                    </Link>
                  )}
                </div>
                <div className='signin-form'>
                  <Formik
                    initialValues={{
                      name: '',
                      email: '',
                      phone: '',
                      password: '',
                      confirm_password: '',
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string().required('Required'),
                      email: Yup.string().email().required('Required').email(),
                      phone: Yup.number()
                        .typeError("That doesn't look like a phone number")
                        .required('Required'),
                      password: Yup.string().required('Required'),
                      confirm_password: Yup.string()
                        .required('Required')
                        .oneOf(
                          [Yup.ref('password'), null],
                          'Passwords must match'
                        ),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      console.log('Submitted');

                      await register({
                        username: values.email,
                        email: values.email,
                        password: values.password,
                        name: values.name,
                        phone: values.phone,
                      });
                      if (BuyNow) {
                        history.push('/buy-now');
                      } else {
                        // history.push("/my-account");
                      }
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => {
                      console.log('Submitting', isSubmitting);
                      return (
                        <Form>
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control'
                              name='name'
                              placeholder='Enter Full Name'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                            />
                            <i className='icon fas fa-user' />
                          </div>
                          {errors.name && touched.name && (
                            <p className='text-danger'> {errors.name} </p>
                          )}
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control'
                              id='phone'
                              name='phone'
                              placeholder='Enter Phone No'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone}
                            />
                            <i className='icon fas fa-phone' />
                          </div>
                          {errors.phone && touched.phone && (
                            <p className='text-danger'> {errors.phone} </p>
                          )}
                          <div className='form-group'>
                            <input
                              type='text'
                              className='form-control'
                              id='email'
                              name='email'
                              placeholder='Enter Email'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <i className='icon fas fa-envelope' />
                          </div>
                          {errors.email && touched.email && (
                            <p className='text-danger'> {errors.email} </p>
                          )}
                          <div className='form-group'>
                            <input
                              type='password'
                              className='form-control'
                              id='password'
                              name='password'
                              placeholder='Enter Password'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            <i className='icon fas fa-lock' />
                          </div>
                          {errors.password && touched.password && (
                            <p className='text-danger'> {errors.password} </p>
                          )}
                          <div className='form-group'>
                            <input
                              type='password'
                              className='form-control'
                              id='confirm_password'
                              name='confirm_password'
                              placeholder='Enter Password Again'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirm_password}
                            />
                            <i className='icon fas fa-lock' />
                          </div>
                          {errors.confirm_password &&
                            touched.confirm_password && (
                              <p className='text-danger'>
                                {' '}
                                {errors.confirm_password}{' '}
                              </p>
                            )}
                          {alert &&
                            alert.map((item) => {
                              return (
                                <p className='text-danger'> {item.msg} </p>
                              );
                            })}
                          <p></p>
                          <button
                            type='submit'
                            className='submit-btn'
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? 'Processing' : 'Sign Up'}
                          </button>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertBox />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth, alert: state.alert });

const mapDispatchToProps = { register };

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Footer from '../components/template/Footer';
import Header from '../components/template/Header';
import { Formik, Form } from 'formik';
import { addTrial, getTrials } from '../store/actions/trials_action';

import * as Yup from 'yup';
import AlertBox from '../components/template/AlertBox';
import { useState } from 'react';
export const TryNow = ({ addTrial }) => {
  const [showTrialBtn, setShowTrialBtn] = useState(false);
  return (
    <div>
      <Header />
      <div className='signin-form-area' style={{ paddingTop: 100 }}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 offset-lg-2'>
              <div className='form-wrap wrap-two my-5'>
                <div className='signin-top d-flex justify-content-between align-items-center'>
                  <h2 className='signin-title'>Try Now for 15 Days</h2>
                </div>
                {!showTrialBtn ? (
                  <div className='signin-form'>
                    <Formik
                      initialValues={{
                        name: '',
                        email: '',
                        phone: '',
                      }}
                      validationSchema={Yup.object({
                        name: Yup.string().required('Required'),
                        email: Yup.string().email().required('Required'),
                        phone: Yup.string().required('Required'),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        await addTrial(values, setShowTrialBtn);

                        setSubmitting(false);
                        resetForm();
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => {
                        console.log('Submitting', isSubmitting);
                        return (
                          <Form>
                            <div className='form-group'>
                              <input
                                type='text'
                                className='form-control'
                                name='name'
                                placeholder='Enter Full Name'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                              <i className='icon fas fa-user' />
                            </div>
                            {errors.name && touched.name && (
                              <p className='text-danger'> {errors.name} </p>
                            )}
                            <div className='form-group'>
                              <input
                                type='number'
                                className='form-control'
                                id='phone'
                                name='phone'
                                placeholder='Enter Phone No'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                              />
                              <i className='icon fas fa-phone' />
                            </div>
                            {errors.phone && touched.phone && (
                              <p className='text-danger'> {errors.phone} </p>
                            )}
                            <div className='form-group'>
                              <input
                                type='text'
                                className='form-control'
                                id='email'
                                name='email'
                                placeholder='Enter Email'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <i className='icon fas fa-envelope' />
                            </div>
                            {errors.email && touched.email && (
                              <p className='text-danger'> {errors.email} </p>
                            )}

                            <button
                              type='submit'
                              className='submit-btn'
                              disabled={isSubmitting}
                            >
                              {isSubmitting
                                ? 'Processing'
                                : 'Download Trial Now'}
                            </button>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                ) : (
                  <div>
                    <div>
                      <p>
                        {' '}
                        Your trial is ready to download. Click on the link below
                        to download{' '}
                      </p>
                      <div className='text-center'>
                        <a
                          href='/docs/TotalPCScanSetup.exe'
                          target='_blank'
                          className='primary-btn'
                        >
                          Download Now
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertBox />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { addTrial };

export default connect(mapStateToProps, mapDispatchToProps)(TryNow);

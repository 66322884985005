import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const Footer = (props) => {
  return (
    <footer className='footer-area'>
      <div className='footer-bottom'>
        <div className='container'>
          <div className='footer-bottom-wrap'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <div className='copyright-area'>
                  <img
                    src='/assets/images/icon-cc.png'
                    style={{ width: '300px', paddingBottom: '20px' }}
                  />
                  <p>
                    {new Date().getFullYear()} © All rights reserved by
                    <Link to='/'>Total PC Scan</Link> Owned By Ninja Development
                    LLC
                  </p>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='footer-bottom-right text-right'>
                  <ul>
                    <li>
                      <Link to='/eula'>End User License Agreement</Link>
                    </li>
                    <li>
                      <Link to='/how-to-uninstall'>How to uninstall</Link>
                    </li>
                    <li>
                      <Link to='/contact'>Consumer Support</Link>
                    </li>
                    <li>
                      <Link to='/terms-and-conditions'>
                        Terms &amp; Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to='/refund-policy'>Refund Policy</Link>
                    </li>
                    <li>
                      <Link to='/faq'>FAQ</Link>
                    </li>
                    <li>
                      <Link to='/privacy-policy'>Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="call-to-action">
        <a href="tel:18005360613">
          <div>Need Help?</div>
          <div style={{ fontSize: "1.2em" }}>+1 800-536-0613</div>
        </a>
      </div> */}
    </footer>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

import React from 'react';
import { connect } from 'react-redux';
import Footer from '../components/template/Footer';
import Header from '../components/template/Header';
const EULA = (props) => {
  return (
    <div>
      <Header />
      <section className='terms-area section' style={{ paddingTop: 150 }}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 offset-lg-3'>
              <div className='section-title-area text-center'>
                <h2 className='section-title'>
                  ‘Total PC Scan’ End User’s License Agreement
                </h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='terms-wrap'>
                <div className='terms-top'>
                  <p>
                    Before you use the Licensed Software, please read this EULA
                    as carefully as you would read any other legal document.
                    This End User License Agreement (EULA) is a contract between
                    you (“you,” “your” or “Licensee”) and Total PC Scan, which
                    provides the terms and conditions under which the Licensed
                    Software named above is licensed – not sold – to you. The
                    Licensed Software includes computer software and may include
                    associated media, printed materials, “online” or electronic
                    documentation, and Internet-based services.
                  </p>
                  <p></p>
                  <p>
                    BY INSTALLING, COPYING, OR OTHERWISE USING THE LICENSED
                    SOFTWARE, YOU AGREE TO BE BOUND BY THE TERMS OF THIS EULA.
                    IF YOU DO NOT AGREE, DO NOT INSTALL OR USE THE LICENSED
                    SOFTWARE. You may return it within 30 calendar days after
                    acquiring it to your place of purchase for a full refund
                    (less, if applicable, shipping, handling, and any taxes),
                    provided that you have not installed, accessed, or used the
                    Licensed Software. The terms and conditions of our Privacy
                    Policy which apply to, and govern our use of any
                    registration data and other information that you may provide
                    to us in relation to the use of the Licensed Software, can
                    be found on our website at:
                    http://www.totalpcscan.com/privacy-policy Please, take the
                    time to read them.
                  </p>
                  <p></p>
                  <p>
                    If you are a residing in, located in, or citizen of the
                    United-States when purchasing the Licensed Software, please
                    be aware that this EULA provides for Class Action Waiver (as
                    set forth below, the “Class Action Waiver” provision) and
                    for your Disputes (as defined below) with Total PC Scan to
                    be referred to binding Arbitration (as set forth below, in
                    the “Dispute Resolution” provision), which may affect your
                    rights under this EULA. You may opt out of the binding
                    individual arbitration and class action waiver as provided
                    below.
                  </p>
                  <p></p>
                  <p>
                    <strong> LICENSE TERMS </strong>
                  </p>
                  <p>
                    <strong> CONTENTS </strong>
                  </p>
                  <p>
                    The “Licensed Software” includes all of the contents of the
                    files, disk(s), CD-ROM(s), DVDs, or other media for which
                    this EULA is provided, including:
                  </p>
                  <p>
                    (1) third-party computer information or software that Total
                    PC Scan has licensed for inclusion in the Licensed Software;
                  </p>
                  <p>
                    (2) Written materials or files relating to the Licensed
                    Software (“Documentation”);
                  </p>
                  <p>
                    (3) Upgrades, modified versions, updates, additions, and
                    copies of the Licensed Software, if any (collectively,
                    “Updates”).
                  </p>
                  <p></p>
                  <p>
                    <strong> INSTALLATION AND ACTIVATION </strong>
                  </p>
                  <p>
                    Unless Licensee has purchased a Site License Agreement,
                    Licensee may only install and/or use one copy of the
                    Licensed Software per user license included with the
                    Licensed Software. To determine how many user licenses were
                    included with the Licensed Software, Licensee should consult
                    the Licensed Software packaging or, in the case of Licensed
                    Software purchased via electronic download, Licensee’s order
                    receipt. If you desire additional user licenses for the
                    Licensed Software, you may purchase them on the user portal
                    at our website for the prices designated on such website.
                    Licensee shall be solely responsible for all expenses
                    incurred in Licensee’s installation of the Licensed
                    Software.
                  </p>
                  <p></p>
                  <p>
                    The Licensed Software contains technological measures that
                    are designed to prevent its unlicensed or illegal use. The
                    Licensed Software may contain enforcement technology that
                    limits Licensee’s ability to install and uninstall the
                    Licensed Software on a machine to no more than a finite
                    number of times, and for a finite number of machines, to
                    ensure that you comply with the terms of this EULA and do
                    not exceed the maximum number of user licenses you have
                    purchased.
                  </p>
                  <p></p>
                  <p>
                    The Licensed Software may require activation as explained
                    during installation and in the Documentation. Such
                    activation may require that you submit a serial number and
                    register an account with us. (Note that if you are
                    installing a trial version of the Licensed Software, you may
                    not need a serial number to activate it, but we may limit
                    the length of time for which you may use the Licensed
                    Software and/or the scope of the Licensed Software
                    Functionality.) If activation is required and not completed
                    within the finite period of time set forth in the
                    Documentation and/or explained during installation, then the
                    Licensed Software will cease to function until activation
                    has been completed, at which time functionality will be
                    restored. If Licensee has any problem with the activation
                    process, Licensee should contact Total PC Scan at
                    http://www.totalpcscan.com/, on the section “Support”.
                  </p>
                  <p></p>
                  <p>
                    The Licensed Software is designed for use on physical
                    devices (i.e. desktop & laptop computers) and may not
                    function fully on virtual machines.
                  </p>
                  <p></p>
                  <p>
                    <strong> INTERNET ACCESS </strong>
                  </p>
                  <p>
                    The Licensed Software may require an internet connection to
                    be available in order to access all features.
                  </p>
                  <p></p>
                  <p>
                    See the specific provisions below that disclaim warranties
                    and limit our liability based on internet service
                    interruptions and unavailability.
                  </p>
                  <p></p>
                  <p>
                    <strong> PERSONAL DATA PROTECTION </strong>
                  </p>
                  <p>
                    You understand that by using the Licensed Software, you
                    consent and agree to the collection and use of certain
                    information about you and your use of the Licensed Software
                    in accordance with Total PC Scan’s Privacy Policy. You
                    further consent and agree that Total PC Scan may collect,
                    use, transmit, process and maintain information related to
                    your Account, and any devices or computers registered
                    thereunder, for purposes of providing the Licensed Software,
                    and any features therein, to you. Information collected by
                    Total PC Scan when you use the Licensed Software may also
                    include technical or diagnostic information related to your
                    use that may be used by Total PC Scan to support, improve
                    and enhance Total PC Scan’s products and services. For more
                    information please read our full privacy policy at
                    http://www.totalpcscan.com/privacy-policy , on the section
                    Privacy Policy. You further understand and agree that this
                    information may be transferred to the United States and/or
                    other countries for storage, processing and use by Total PC
                    Scan, its affiliates, and/or their service providers. You
                    hereby agree and consent to Total PC Scan’s and its
                    partners’ and licensors’ collection, use, transmission,
                    processing and maintenance of such location and account data
                    to provide and improve such features or services.
                  </p>
                  <p></p>
                  <p>
                    <strong> COPIES </strong>
                  </p>
                  <p>
                    Licensee may only make one copy of the Licensed Software for
                    backup or archival purposes only, except that the
                    Documentation may not be duplicated.
                  </p>
                  <p></p>
                  <p>
                    <strong> TRANSFER </strong>
                  </p>
                  <p>
                    Licensee may not sell, assign, sublicense, rent, lease, lend
                    or otherwise transfer the Licensed Software or the License
                    granted by this EULA without prior written consent of Total
                    PC Scan.
                  </p>
                  <p></p>
                  <p>
                    <strong> USE </strong>
                  </p>
                  <p>
                    The License granted by this EULA is non-exclusive. Licensee
                    may not use the Licensed Software except as expressly
                    permitted by this License.
                  </p>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p>
                    <strong> PROHIBITED USES </strong>
                  </p>
                  <p>
                    (1) Licensee may not modify, alter, adapt or translate all
                    or any portion of the Licensed Software;
                  </p>
                  <p>
                    (2) Licensee may not create any derivative works from all or
                    any portion of the Licensed Software;
                  </p>
                  <p>
                    (3) Licensee may not reverse-engineer, decompile,
                    disassemble, or otherwise attempt to discover the source
                    code of the Licensed Software;
                  </p>
                  <p>
                    (4) Licensee may not use a previous version of the Licensed
                    Software after receiving a media replacement or upgraded
                    version as a replacement to a prior version (in such case
                    you must destroy the prior version);
                  </p>
                  <p>
                    (5) Licensee may not use the Licensed Software in the
                    operation of aircraft, ship, nuclear facilities,
                    life-support machines, communication systems, or any other
                    equipment in which the failure of the software could lead to
                    personal injury, death, or environmental damage;
                  </p>
                  <p>
                    (6) Licensee may not remove or obscure Total PC Scan’s
                    copyright or trademark notices, or the copyright and
                    trademark notices of third parties that Total PC Scan has
                    included in the Licensed Software; and
                  </p>
                  <p>
                    (7) Licensee may not use the Licensed Software to host
                    applications for third parties, as part of a facility
                    management, timesharing, service provider, or service bureau
                    arrangement; and
                  </p>
                  <p>
                    (8) Licensee may not use the Licensed Software in any manner
                    not authorized by this EULA.
                  </p>
                  <p></p>
                  <p>
                    <strong> SOFTWARE UPDATES </strong>
                  </p>
                  <p>
                    If the Licensed Software is an Update to a previous version,
                    Licensee must possess a valid License to the previous
                    version. Any Update provided to Licensee is made on a
                    License-exchange basis such that Licensee agrees, as a
                    condition for receiving an Update, that Licensee will
                    terminate all of Licensee’s rights to use any previous
                    version of the Licensed Software. However, Licensee may
                    continue to use the previous version only to assist in
                    transitioning to the Updated version. Once an Update has
                    been released, Total PC Scan may cease support for prior
                    versions, without any notice to Licensee.
                  </p>
                  <p></p>
                  <p>
                    <strong> CONTENT UPDATES </strong>
                  </p>
                  <p>
                    Total PC Scan may provide Updates to the content of some of
                    its software from time to time, including but not limited
                    to, virus definitions, URL lists, rules, driver database
                    updates, and updated vulnerability data. These types of
                    Updates are collectively referred to as “Content Updates.”
                    Total PC Scan may, at its discretion and without notice,
                    add, modify or remove features, including Content Updates,
                    from the Licensed Software at any time.
                  </p>
                  <p></p>
                  <p>
                    <strong> SUPPORT </strong>
                  </p>
                  <p>
                    Total PC Scan is not obligated by this EULA to provide
                    Licensee with any technical support services relating to the
                    Licensed Software; however, Licensee may order additional
                    support services for an additional charge as Total PC Scan
                    may offer from time to time during the term of this EULA.
                  </p>
                  <p></p>
                  <p>
                    <strong> GOVERNING LAW </strong>
                  </p>
                  <p>
                    You are a consumer. Nothing in this contract should prevent
                    you from the benefit of rights granted to you by applicable
                    consumer regulation.
                  </p>
                  <p>
                    The law governing this Agreement and any Dispute (as defined
                    in Section 11) will be the substantive law of the U.S.,
                    without regard to choice of laws principles. This Agreement
                    will not be governed by the United Nations Convention on
                    Contracts for the International Sales of Goods, the
                    application of which is expressly excluded.
                  </p>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p>
                    <strong> DISPUTE RESOLUTION </strong>
                  </p>
                  <p>
                    IF YOU ARE RESIDENT, LOCATED IN, CITIZEN OF THE
                    UNITED-STATES, WHEN PURCHASING THE LICENSED SOFTWARE THE
                    FOLLOWING DISPUTE RESOLUTION MECHANISM APPLIES TO YOU:
                  </p>
                  <p>
                    This provision facilitates the prompt and efficient
                    resolution of any Disputes that may arise between you and
                    Total PC Scan. Arbitration is a form of private dispute
                    resolution in which persons with a dispute waive their
                    rights to file a lawsuit, to proceed in court and to a jury
                    trial, and instead submit their disputes to a neutral third
                    person (or arbitrator) for a binding decision. You have the
                    right to opt-out of this Provision (as explained below),
                    which means you would retain your right to litigate your
                    Disputes in a court, either before a judge or jury.
                  </p>
                  <p></p>
                  <p>
                    Please read this Provision carefully. It provides that all
                    Disputes between you and Total PC Scan (as defined below,
                    for this Provision) shall be resolved by binding
                    arbitration. Arbitration replaces the right to go to court.
                    In the absence of this arbitration agreement, you may
                    otherwise have a right or opportunity to bring claims in
                    court, before a judge or jury, and/or participate in or be
                    represented in a case filed in court by others (including,
                    but not limited to, class actions). Except as otherwise
                    provided, entering into this agreement constitutes a waiver
                    of your right to litigate claims and all opportunity to be
                    heard by a judge or jury. There is no judge or jury in
                    arbitration, and court review of an arbitration award is
                    limited. The arbitrator must follow this agreement and can
                    award the same damages and relief as a court (including
                    attorneys’ fees).
                  </p>
                  <p></p>
                  <p>
                    For the purpose of this Provision, “Total PC Scan” means
                    Total PC Scan and its parents, subsidiary, and affiliate
                    companies, and each of their respective officers, directors,
                    employees, and agents. The term “Dispute” means any dispute,
                    claim, or controversy between you and Total PC Scan
                    regarding any aspect of your relationship with Total PC
                    Scan, whether based in contract, statute, regulation,
                    ordinance, tort (including, but not limited to, fraud,
                    misrepresentation, fraudulent inducement, or negligence), or
                    any other legal or equitable theory, and includes the
                    validity, enforceability or scope of this Provision (with
                    the exception of the enforceability of the Class Action
                    Waiver clause below). “Dispute” is to be given the broadest
                    possible meaning that will be enforced.
                  </p>
                  <p></p>
                  <p>
                    WE EACH AGREE THAT, EXCEPT AS PROVIDED BELOW, ANY AND ALL
                    DISPUTES, AS DEFINED ABOVE, WHETHER PRESENTLY IN EXISTENCE
                    OR BASED ON ACTS OR OMISSIONS IN THE PAST OR IN THE FUTURE,
                    WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING
                    ARBITRATION RATHER THAN IN COURT IN ACCORDANCE WITH THIS
                    PROVISION.
                  </p>
                  <p></p>
                  <p>
                    <strong> Pre-Arbitration Claim Resolution </strong>
                  </p>
                  <p>
                    For all Disputes, whether pursued in court or arbitration,
                    you must first give Total PC Scan an opportunity to resolve
                    the Dispute. You must commence this process by mailing
                    written notification to Total PC Scan, 17129 Bear Valley
                    Road Hesperia CA USA 92345.That written notification must
                    include (1) your name, (2) your address, (3) a written
                    description of your Dispute, and (4) a description of the
                    specific relief you seek. If Total PC Scan does not resolve
                    the Dispute within 45 days after it receives your written
                    notification, you may pursue your Dispute in arbitration.
                    You may pursue your Dispute in a court only under the
                    circumstances described below.
                  </p>
                  <p></p>
                  <p>
                    <strong>
                      {' '}
                      Exclusions from Arbitration/Right to Opt Out{' '}
                    </strong>
                  </p>
                  <p>
                    Notwithstanding the above, you or PC Total PC Scan may
                    choose to pursue a Dispute in court and not by arbitration
                    if
                  </p>
                  <p>
                    (a) The Dispute qualifies, it may be initiated in small
                    claims court; or
                  </p>
                  <p>
                    (b) YOU OPT-OUT OF THESE ARBITRATION PROCEDURES WITHIN 30
                    DAYS FROM THE DATE THAT YOU FIRST CONSENT TO THIS AGREEMENT
                    (the “Opt-Out Deadline”). You may opt out of this Provision
                    by mailing written notification to Total PC Scan, Legal
                    Department, 17129 Bear Valley Road Hesperia CA USA 92345.
                    Your written notification must include (1) your name, (2)
                    your address, and (3) a clear statement that you do not wish
                    to resolve disputes with Total PC Scan through arbitration.
                    Your decision to opt-out of this Provision will have no
                    adverse effect on your relationship with Total PC Scan. Any
                    opt-out request received after the Opt-Out Deadline will not
                    be valid and you must pursue your Dispute in arbitration or
                    small claims court.
                  </p>
                  <p></p>
                  <p>
                    <strong> Arbitration Procedures </strong>
                  </p>
                  <p>
                    <strong>
                      If this Provision applies and the Dispute is not resolved
                      as provided above (“Pre-Arbitration Claim Resolution”)
                      either you or Total PC Scan may initiate arbitration
                      proceedings. The arbitration shall be commenced as an
                      individual arbitration, and shall in no event be commenced
                      as class arbitration. All issues shall be for the
                      arbitrator to decide, including the scope of this
                      Provision.
                    </strong>
                  </p>
                  <p></p>
                  <p>
                    The JAMS Comprehensive Arbitration Rules & Procedures and
                    the JAMS Recommended Arbitration Discovery Protocols For
                    Domestic, Commercial Cases will apply. The JAMS rules are
                    available at www.jamsadr.com or by calling 1-800-352-5267.
                    This Provision governs in the event it conflicts with the
                    applicable arbitration rules. Under no circumstances will
                    class action procedures or rules apply to the arbitration.
                  </p>
                  <p></p>
                  <p>
                    Because this EULA and the Licensed Software concern
                    interstate commerce, the Federal Arbitration Act (“FAA”)
                    governs the arbitrability of all Disputes. However, the
                    arbitrator will apply applicable substantive law consistent
                    with the FAA and the applicable statute of limitations or
                    condition precedent to suit.
                  </p>
                  <p>
                    <strong> Arbitration Award </strong>
                  </p>
                  <p>
                    <strong>
                      The arbitrator may award on an individual basis any relief
                      that would be available pursuant to applicable law, and
                      will not have the power to award relief to, against or for
                      the benefit of any person who is not a party to the
                      proceeding. The arbitrator will make any award in writing
                      but need not provide a statement of reasons unless
                      requested by a party. Such award will be final and binding
                      on the parties, except for any right of appeal provided by
                      the FAA, and may be entered in any court having
                      jurisdiction over the parties for purposes of enforcement.
                    </strong>
                  </p>
                  <p></p>
                  <p>
                    <strong> Location of Arbitration </strong>
                  </p>
                  <p>
                    You or Total PC Scan may initiate arbitration in either Los
                    Angeles, California or the federal judicial district that
                    includes the address you provide in your written
                    notification of Pre-Arbitration Claim Resolution. In the
                    event that you select the federal judicial district that
                    includes the address you provide in your written
                    notification of Pre-Arbitration Claim Resolution, Total PC
                    Scan may transfer the arbitration to Los Angeles, California
                    in the event that it agrees to pay any additional fees or
                    costs you incur as a result of the transfer, as determined
                    by the arbitrator.
                  </p>
                  <p></p>
                  <p>
                    <strong> Payment of Arbitration Fees and Costs </strong>
                  </p>
                  <p>
                    Total PC Scan will pay all arbitration filing fees and
                    arbitrator’s costs and expenses upon your written request
                    given prior to the commencement of the arbitration. You are
                    responsible for all additional fees and costs that you incur
                    in the arbitration, including, but not limited to, attorneys
                    or expert witnesses. Fees and costs may be awarded as
                    provided pursuant to applicable law. In addition to any
                    rights to recover fees and costs under applicable law, if
                    you provide notice and negotiate in good faith with Total PC
                    Scan as provided in the section above titled
                    “Pre-Arbitration Claim Resolution” and the arbitrator
                    concludes that you are the prevailing party in the
                    arbitration, you will be entitled to recover reasonable
                    attorney’s fees and costs as determined by the arbitrator.
                  </p>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p>
                    <strong> Class Action Waiver </strong>
                  </p>
                  <p>
                    <strong>
                      Except as otherwise provided in this Provision, the
                      arbitrator may not consolidate more than one person’s
                      claims, and may not otherwise preside over any form of a
                      class or representative proceeding or claims (such as a
                      class action, consolidated action or private attorney
                      general action) unless both you and Total PC Scan
                      specifically agree to do so following initiation of the
                      arbitration. If you choose to pursue your Dispute in court
                      by opting out of this Provision, as specified above in
                      section “Exclusions from Arbitration/Right to Opt Out”,
                      this Class Action Waiver will not apply to you. Neither
                      you, nor any other user of the Licensed Software can be a
                      class representative, class member, or otherwise
                      participate in a class, consolidated, or representative
                      proceeding without having complied with the opt-out
                      requirements above.
                    </strong>
                  </p>
                  <p></p>
                  <p>
                    <strong> Jury Waiver </strong>
                  </p>
                  <p>
                    You understand and agree that by entering into this
                    agreement you and Total PC Scan are each waiving the right
                    to a jury trial or a trial before a judge in a public court.
                    In the absence of this Provision, you and Total PC Scan
                    might otherwise have had a right or opportunity to bring
                    Disputes in a court, before a judge or jury, and/or to
                    participate or be represented in a case filed in court by
                    others (including class actions). Except as otherwise
                    provided below, those rights are waived. Other rights that
                    you would have if you went to court, such as the right to
                    appeal and to certain types of discovery, may be more
                    limited or may also be waived.
                  </p>
                  <p></p>
                  <p>
                    <strong> Severability </strong>
                  </p>
                  <p>
                    If any clause within this Provision (other than the Class
                    Action Waiver clause above) is found to be illegal or
                    unenforceable, that clause will be severed from this
                    Provision, and the remainder of this Provision will be given
                    full force and effect. If the Class Action Waiver clause is
                    found to be illegal or unenforceable, this entire Provision
                    will be unenforceable and the Dispute will be decided by a
                    court.
                  </p>
                  <p></p>
                  <p>
                    Review by Arbitrator. Any dispute regarding this Provision,
                    including the enforceability of this Provision or the
                    arbitrability of any Dispute as provided in this Provision,
                    shall be for the arbitrator to determine.
                  </p>
                  <p></p>
                  <p>
                    <strong> Continuation </strong>
                  </p>
                  <p>
                    This Provision “DISPUTE RESOLUTION” shall survive the
                    termination of your use of the Licensed Software and any
                    related Total PC Scan websites or services.
                  </p>
                  <p></p>
                  <p>
                    <strong> LIMITED WARRANTY ON MEDIA </strong>
                  </p>
                  <p>
                    Total PC Scan warrants that the media on which the Licensed
                    Software is distributed will be free from material defects
                    for a period of 30 days from the date the Licensed Software
                    is delivered to Licensee. If Licensee discovers a defect in
                    the media during this 30-day period, Licensee may return the
                    defective media to Total PC Scan, and Licensee’s sole remedy
                    is to have either the defective media replaced, or at Total
                    PC Scan’s sole option, a refund of the money that Licensee
                    paid for the Licensed Software.
                  </p>
                  <p></p>
                  <p>
                    <strong> NO WARRANTY ON LICENSED SOFTWARE </strong>
                  </p>
                  <p>
                    THE LICENSED SOFTWARE (EXLUDING THE MEDIA ON WHICH IT IS
                    DISTRIBUTED) AND ALL PC HELPSOFT RELATED WEBSITES AND
                    SERVICES ARE PROVIDED TO LICENSEE “AS IS” AND “AS
                    AVAILABLE,” AND Total PC Scan AND ITS SUPPLIERS AND
                    LICENSORS MAKE NO WARRANTY AS TO THEIR USE OR PERFORMANCE.
                    Total PC Scan AND ITS SUPPLIERS AND LICENSORS MAKE NO
                    WARRANTIES OR REPRESENTATIONS (EXPRESS OR IMPLIED, WHETHER
                    BY STATUTE, COMMON LAW, CUSTOM, USAGE, OR OTHERWISE) AS TO
                    ANY MATTER INCLUDING WITHOUT LIMITATION: QUALITY,
                    AVAILABILITY, PERFORMANCE OR FUNCTIONALITY OF THE LICENSED
                    SOFTWARE OR ANY RELATED Total PC Scan WEBSITES OR SERVICES;
                    QUALITY OR ACCURACY OF ANY INFORMATION OBTAINED FROM OR
                    AVAILABLE THROUGH USE OF THE LICENSED SOFTWARE OR RELATED
                    Total PC Scan WEBSITES OR SERVICES; ANY REPRESENATION OR
                    WARRANTY THAT THE USE OF THE LICENSED SOFTWARE OR ANY
                    RELATED Total PC Scan WEBSITES OR SERVICES WILL BE
                    UNINTERRUPTED OR ALWAYS AVAILABLE (WHETHER DUE TO INTERNET
                    FAILURE OR OTHERWISE), ERROR-FREE, FREE OF VIRUSES OR OTHER
                    HARMFUL COMPONENTS OR OPERATE ON OR WITH ANY PARTICULAR
                    HARDWARE, PLATFORM OR SOFTWARE; NON-INFRINGEMENT OF
                    THIRD-PARTY RIGHTS, MERCHANTABILITY, INTEGRATION,
                    SATISFACTORY QUALITY, OR FITNESS FOR ANY PARTICULAR PURPOSE,
                    EXCEPT FOR, AND ONLY TO THE EXTENT, THAT A WARRANTY MAY NOT
                    BE EXCLUDED OR LIMITED BY APPLICABLE LAW IN LICENSEE’S
                    JURISDICTION.
                  </p>
                  <p></p>
                  <p>
                    <strong> LIMITATION OF LIABILITY </strong>
                  </p>
                  <p>
                    IN NO EVENT WILL Total PC Scan, OR Total PC Scan’S SUPPLIERS
                    OR LICENSORS, BE LIABLE TO LICENSEE FOR ANY DAMAGES, CLAIMS,
                    OR COSTS WHATSOEVER, OR FOR ANY CONSEQUENTIAL, INDIRECT,
                    EXEMPLARY, SPECIAL, PUNATIVE, INCIDENTAL DAMAGES, OR ANY
                    LOST PROFITS OR LOST SAVINGS (INCLUDING WITHOUT LIMITATION
                    THOSE BASED ON THE USE OR THE INABILITY TO USE THE LICENSED
                    SOFTWARE OR ANY Total PC Scan RELATED WEBSITES OR SERVICES),
                    EVEN IF A REPRESENTATIVE OF Total PC Scan OR ONE OF TOTAL PC
                    SCAN’S SUPPLIERS OR LICENSORS HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH LOSS, DAMAGES, CLAIMS, OR COSTS, OR FOR
                    ANY CLAIM BY ANY THIRD PARTY. THESE LIMITATIONS AND
                    EXCLUSIONS APPLY TO THE EXTENT PERMITTED BY APPLICABLE LAW
                    IN LICENSEE’S JURISDICTION. IN ANY CASE, THE AGGREGATE
                    LIABILITY OF Total PC Scan, AND Total PC Scan’S SUPPLIERS,
                    UNDER OR IN CONNECTION WITH THIS EULA, SHALL BE LIMITED TO
                    THE AMOUNT PAID FOR THE LICENSED SOFTWARE.
                  </p>
                  <p></p>
                  <p>
                    <strong> ADDITIONAL TERMS FOR BETA SOFTWARE </strong>
                  </p>
                  <p>
                    If the Licensed Software that Licensee receives with this
                    EULA is pre-commercial release or “BETA” software
                    (“Pre-release Software”), then, to the extent that any
                    provision in this section is in conflict with any other term
                    or condition in this EULA, this section supersedes such
                    conflicting term(s) and condition(s) as to the Pre-release
                    Software, but only to the extent necessary to resolve the
                    conflict.
                  </p>
                  <p></p>
                  <p>
                    Licensee acknowledges that the Pre-release Software does not
                    represent the final product from Total PC Scan, and may
                    contain bugs, errors, and other problems that could cause
                    system or other failures and data loss. Consequently,
                    disclaims any warranty or liability obligations to Licensee
                    of any kind whatsoever.
                  </p>
                  <p></p>
                  <p>
                    IN APPLICABLE JURISDICTIONS WHERE LIABILITY CANNOT BE SO
                    EXCLUDED FOR PRE-RELEASE SOFTWARE, BUT MAY BE LIMITED, PC
                    HELPSOFT’S LIABILITY, AND THAT OF ITS SUPPLIERS, SHALL BE
                    LIMITED TO THE TOTAL OF FIFTY EUROS (50 EUR).
                  </p>
                  <p></p>
                  <p>
                    Licensee acknowledges that Total PC Scan has not promised or
                    guaranteed to Licensee that the Pre-release Software will be
                    announced or made available to anyone in the future, and
                    that Total PC Scan has no express or implied obligation to
                    Licensee to announce or introduce the Pre-release Software.
                    Total PC Scan may decide not to introduce a product similar
                    to, or compatible with, the Pre-release Software.
                    Accordingly, Licensee acknowledges that any research or
                    development that Licensee performs regarding the Pre-release
                    Software, or any product associated with the Pre-release
                    Software, is done entirely at Licensee’s own risk.
                  </p>
                  <p></p>
                  <p>
                    During the term of this EULA, if requested by Total PC Scan,
                    Licensee will provide feedback to Total PC Scan regarding
                    testing and use of the Pre-release Software, including error
                    or bug reports.
                  </p>
                  <p></p>
                  <p>
                    If Licensee has been provided the Pre-release Software
                    pursuant to a separate written agreement, then Licensee’s
                    use of the Pre-release Software is also governed by that
                    agreement. Notwithstanding anything in this EULA to the
                    contrary, if Licensee is located outside the United States,
                    Licensee will return or destroy all unreleased versions of
                    the Pre-release Software within 30 days of the completion of
                    Licensee’s testing of the Pre-release Software if that date
                    is earlier than the date scheduled for Total PC Scan’s first
                    commercial shipment of the publicly released (commercial)
                    software.
                  </p>
                  <p></p>
                  <p>
                    <strong> SURVIVAL OF DISCLAIMERS </strong>
                  </p>
                  <p>
                    The exclusions of warranties and liability limitations shall
                    survive the termination of this EULA, howsoever caused; but
                    this survival shall not imply or create any continued right
                    to use the Licensed Software after termination of this EULA.
                  </p>
                  <p></p>
                  <p>
                    <strong> EXPORT RULES </strong>
                  </p>
                  <p>
                    Licensee shall not ship, transfer, or export Licensed
                    Software into any country or use Licensed Software in any
                    manner prohibited by the applicable export control laws,
                    notably where applicable, the United States Export
                    Administration Act, restrictions, or regulations
                    (collectively the “Export Laws.”) All rights to use the
                    Licensed Software are granted on condition that Licensee
                    complies with the Export Laws, and all such rights are
                    forfeited if Licensee fails to comply with the Export Laws.
                  </p>
                  <p></p>
                  <p>
                    <strong> INTELLECTUAL PROPERTY OWNERSHIP </strong>
                  </p>
                  <p>
                    The Licensed Software and any authorized copies that
                    Licensee makes are the intellectual property of, and are
                    owned by, Total PC Scan, and by third parties whose
                    intellectual property has been licensed by Total PC Scan.
                    The structure, organization, and code of the Licensed
                    Software are the valuable trade secrets and confidential
                    information of Total PC Scan and such third parties. The
                    Licensed Software is protected by law, including without
                    limitation, the copyright laws of the United States and
                    other countries, and by international treaty provisions.
                    Except as expressly provided in this EULA, Licensee is not
                    granted any intellectual property rights in the Licensed
                    Software.
                  </p>
                  <p></p>
                  <p>
                    <strong> RESERVATION OF RIGHTS </strong>
                  </p>
                  <p>
                    Total PC Scan reserves all rights not expressly granted to
                    Licensee by this EULA. The rights granted to Licensee are
                    limited to Total PC Scan’s intellectual property rights, and
                    to the intellectual property rights of third parties
                    licensed by Total PC Scan. All rights are reserved under the
                    copyright laws of USA and/or of other countries, to 17129
                    Bear Valley Road Hesperia CA, USA 92345.
                  </p>
                  <p></p>
                  <p>
                    <strong> COMPLETE AGREEMENT and BINDING EFFECT </strong>
                  </p>
                  <p>
                    This EULA constitutes the entire agreement between the
                    Licensee and Total PC Scan relating to the Licensed
                    Software, and it supersedes all prior or contemporaneous
                    representations, discussions, undertakings, communications,
                    agreements, arrangements, advertisements, and understandings
                    regulating the Licensed Software. This EULA is binding on
                    and made for the benefit of the parties and their successors
                    and permitted assigns.
                  </p>
                  <p></p>
                  <p>
                    <strong> MODIFICATION </strong>
                  </p>
                  <p>
                    This EULA may only be modified, supplemented or amended by a
                    writing signed by an authorized officer of Total PC Scan.
                  </p>
                  <p></p>
                  <p>
                    <strong> SEVERABILITY </strong>
                  </p>
                  <p>
                    Except as provided in the “Dispute Resolution and
                    Arbitration” Provision, if any provision of this EULA is
                    determined by a court of competent jurisdiction to be
                    contrary to law, that provision will be enforced to the
                    maximum extent permissible, and the remaining provisions of
                    this EULA will remain in full force and effect.
                  </p>
                  <p></p>
                  <p>
                    <strong> WAIVER </strong>
                  </p>
                  <p>
                    No failure or delay by Total PC Scan in exercising its
                    rights or remedies shall operate as a waiver unless made by
                    Total PC Scan’s specific written notice. No single or
                    partial exercise of any right or remedy of Total PC Scan
                    shall operate as a waiver or preclude any other, or further,
                    exercise of that, or any other right, or remedy.
                  </p>
                  <p></p>
                  <p>
                    <strong> PROOF OF COMPLIANCE </strong>
                  </p>
                  <p>
                    Within 30 calendar days after request from Total PC Scan, or
                    Total PC Scan’s authorized representative, Licensee will
                    provide full documentation, and certify under penalty of
                    perjury, that Licensee’s use of any and all Licensed
                    Software is in conformity with this EULA.
                  </p>
                  <p></p>
                  <p>TERMINATION</p>
                  <p>
                    If Licensee breaches this EULA, and fails to cure any breach
                    within 30 calendar days after request from Total PC Scan, or
                    Total PC Scan’s authorized representative, Total PC Scan may
                    terminate this EULA, whereupon all rights granted to
                    Licensee shall immediately cease. Furthermore, upon
                    termination, Licensee shall return to Total PC Scan all
                    copies of the Licensed Software, or verify in writing that
                    all copies of the Licensed Software have been destroyed.
                  </p>
                  <p></p>
                  <p>
                    <strong> MONEY-BACK GUARANTEE </strong>
                  </p>
                  <p>
                    If for any reason you, the Licensee, are dissatisfied with
                    the Licensed Software you may obtain a refund of the paid
                    amount for the Licensed Software within 30 calendar days
                    after acquiring it, by contacting Total PC Scan at
                    support@totalpcscan.com to obtain the refund. Once you
                    receive the refund, the License granted by this EULA is no
                    longer valid and any use of the Software will infringe Total
                    PC Scan’s copyright rights.
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EULA);

import { db } from "../../shared/config";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  startAfter,
  endBefore,
  limitToLast,
  limit,
  Timestamp,
  where,
} from "firebase/firestore";
import {
  GET_TRIALS,
  ADD_TRIAL,
  GET_TRIAL,
  RESET_TRIAL,
  TRIALS_ERROR,
} from "../types/trial_type";

import { setAlert } from "./alert";
import { LIMIT } from "../../domain/constant";
//GET TRIALS
const trialsRef = collection(db, "trials");
export const getTrials =
  ({ term, termField, orderValue = "createdAt", orderType = "desc" }) =>
  async (dispatch) => {
    try {
      let searchQuery = null;
      if (term) {
        searchQuery = query(trialsRef, where(termField, "==", term));
      } else {
        searchQuery = query(
          trialsRef,
          orderBy(orderValue, orderType),
          limit(LIMIT)
        );
      }
      const querySnapshot = await getDocs(searchQuery);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: GET_TRIALS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert("Something went wrong", "danger"));
      dispatch({
        type: TRIALS_ERROR,
      });
    }
  };

export const getNextTrials =
  ({ term, termField, last, orderValue = "createdAt", orderType = "desc" }) =>
  async (dispatch) => {
    try {
      let searchQuery = null;
      if (term) {
        searchQuery = query(
          trialsRef,
          orderBy(orderValue, orderType),
          startAfter(last[orderValue]),
          limit(LIMIT),
          where(termField, "==", term)
        );
      } else {
        searchQuery = query(
          trialsRef,
          orderBy(orderValue, orderType),
          startAfter(last[orderValue]),
          limit(LIMIT)
        );
      }
      const querySnapshot = await getDocs(searchQuery);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: GET_TRIALS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert("Something went wrong", "danger"));
      dispatch({
        type: TRIALS_ERROR,
      });
    }
  };
export const getPreviousTrials =
  ({ term, termField, first, orderValue = "createdAt", orderType = "desc" }) =>
  async (dispatch) => {
    try {
      let searchQuery = null;
      if (term) {
        searchQuery = query(
          trialsRef,
          orderBy(orderValue, orderType),
          endBefore(first[orderValue]),
          limitToLast(LIMIT),
          where(termField, "==", term)
        );
      } else {
        searchQuery = query(
          trialsRef,
          orderBy(orderValue, orderType),
          endBefore(first[orderValue]),
          limitToLast(LIMIT)
        );
      }

      const querySnapshot = await getDocs(searchQuery);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: GET_TRIALS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch(setAlert("Something went wrong", "danger"));
      dispatch({
        type: TRIALS_ERROR,
      });
    }
  };

export const addTrial = (data, setShowTrialBtn) => async (dispatch) => {
  try {
    // Check if trial is already activated
    let searchQuery = query(trialsRef, where("email", "==", data.email));
    const querySnapshot = await getDocs(searchQuery);
    const results = [];
    querySnapshot.forEach((doc) => {
      results.push({ id: doc.id, ...doc.data() });
    });
    if (results.length > 0) {
      dispatch(setAlert("Email already exists", "danger"));
    } else {
      data.createdAt = Timestamp.fromDate(new Date());
      const docRef = await addDoc(trialsRef, data);
      dispatch({
        type: ADD_TRIAL,
      });
      dispatch(setAlert("Trial Activated Successfully", "success"));
      setShowTrialBtn(true);
    }
  } catch (e) {
    console.error("Error adding document: ", e);
    dispatch(setAlert("Something went wrong", "danger"));
    dispatch({
      type: TRIALS_ERROR,
    });
  }
};

export const getTrial = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_TRIAL,
    });
    const docRef = doc(db, "trials", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      data.id = id;
      dispatch({
        type: GET_TRIAL,
        payload: data,
      });
    } else {
      dispatch(setAlert("Something went wrong", "danger"));
      dispatch({
        type: TRIALS_ERROR,
      });
    }
  } catch (err) {
    dispatch(setAlert("Something went wrong", "danger"));
    dispatch({
      type: TRIALS_ERROR,
    });
  }
};
export const editTrial = (id, data) => async (dispatch) => {
  try {
    const docSnap = await updateDoc(doc(db, "trials", id), data);
    dispatch(setAlert("Trial updated successfully", "success"));
  } catch (err) {
    dispatch(setAlert("Something went wrong", "danger"));
    dispatch({
      type: TRIALS_ERROR,
    });
  }
};

export const deleteTrial = (id) => async (dispatch) => {
  try {
    const docSnap = await deleteDoc(doc(db, "trials", id));
    dispatch(setAlert("Trial deleted successfully", "success"));
  } catch (err) {
    dispatch(setAlert("Something went wrong", "danger"));
    dispatch({
      type: TRIALS_ERROR,
    });
  }
};

import React, { useState } from "react";
import { connect } from "react-redux";

import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { resetPassword } from "../store/actions/auth";
import { useLocation, useHistory, Link } from "react-router-dom";
import AlertBox from "../components/template/AlertBox";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = ({ resetPassword }) => {
  let query = useQuery();
  const [actionCode, setActionCode] = useState(query.get("oobCode"));
  return (
    <div>
      <Header />
      <div className="signin-form-area mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="form-wrap wrap-two my-5">
                <div className="signin-top d-flex justify-content-between align-items-center">
                  <h2 className="signin-title">Reset Password</h2>
                </div>
                <div className="signin-form">
                  <Formik
                    initialValues={{
                      password: "",
                      passwordConfirmation: "",
                    }}
                    validationSchema={Yup.object({
                      password: Yup.string().required("Required"),

                      passwordConfirmation: Yup.string()
                        .required("Required")
                        .oneOf(
                          [Yup.ref("password"), null],
                          "Passwords must match"
                        ),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      console.log("Submitted");

                      await resetPassword(values.password, actionCode);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => {
                      console.log("Submitting", isSubmitting);
                      return (
                        <Form>
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              name="password"
                              placeholder="Enter Password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            <i className="icon fas fa-lock" />
                          </div>

                          {errors.password && touched.password && (
                            <p className="text-danger"> {errors.password} </p>
                          )}

                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              name="passwordConfirmation"
                              placeholder="Enter Password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.passwordConfirmation}
                            />
                            <i className="icon fas fa-lock" />
                          </div>

                          {errors.passwordConfirmation &&
                            touched.passwordConfirmation && (
                              <p className="text-danger">
                                {" "}
                                {errors.passwordConfirmation}{" "}
                              </p>
                            )}

                          <p></p>
                          <button
                            type="submit"
                            className="submit-btn"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Processing" : "Reset Password"}
                          </button>
                          <div className="text-center p-3">
                            <Link to="/login"> Return to Login </Link>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertBox />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth, alert: state.alert });

const mapDispatchToProps = { resetPassword };

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { login } from "../store/actions/auth";
import { useLocation, useHistory } from "react-router-dom";
import AlertBox from "../components/template/AlertBox";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = ({ auth, login, alert }) => {
  let query = useQuery();
  let history = useHistory();
  const [BuyNow, setBuyNow] = useState(query.get("buy"));
  console.log("Buy Now", BuyNow);
  return (
    <div>
      <Header />
      <div className="signin-form-area mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="form-wrap wrap-two my-5">
                <div className="signin-top d-flex justify-content-between align-items-center">
                  <h2 className="signin-title">Welcome Back</h2>
                  {BuyNow ? (
                    <Link to="/sign-up?buy=true" className="sign-btn">
                      Sign Up
                    </Link>
                  ) : (
                    <Link to="/sign-up" className="sign-btn">
                      Sign Up
                    </Link>
                  )}
                </div>
                <div className="signin-form">
                  <Formik
                    initialValues={{
                      email: "",

                      password: "",
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string().required("Required"),
                      password: Yup.string().required("Required"),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      console.log("Submitted");
                      const formData = {
                        email: values.email,
                        password: values.password,
                      };
                      login(formData);
                      setSubmitting(false);
                      if (BuyNow) {
                        history.push("/buy-now");
                      } else {
                        history.push("/my-account");
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => {
                      console.log("Submitting", isSubmitting);
                      return (
                        <Form>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Enter Email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <i className="icon fas fa-envelope" />
                          </div>
                          {errors.email && touched.email && (
                            <p className="text-danger"> {errors.email} </p>
                          )}
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              name="password"
                              placeholder="Enter Password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            <i className="icon fas fa-lock" />
                          </div>

                          {errors.password && touched.password && (
                            <p className="text-danger"> {errors.password} </p>
                          )}
                          <div className="forgot-password">
                            <Link to="/forgot-password">Forgot Password? </Link>
                          </div>

                          <p></p>
                          <button
                            type="submit"
                            className="submit-btn"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Processing" : "Login"}
                          </button>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertBox />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth, alert: state.alert });

const mapDispatchToProps = { login };

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../store/actions/auth';

const Header = ({ logout, auth: { loading, isAuthenticated, user } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const chatWithUs = () => {
    window.Tawk_API.toggle();
  };
  return (
    <div>
      <header className='header-area transparent-header sticky-bar'>
        <div className='container'>
          <nav className='navbar navbar-expand-lg navbar-light p-0'>
            <Link className='navbar-brand' to='/'>
              <img
                className='main-logo'
                src='assets/images/logo12.png'
                alt='logo'
              />
              <img
                className='sticky-logo'
                src='assets/images/logo12.png'
                alt='logo'
              />
            </Link>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarSupportedContent'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
            <div
              className='collapse navbar-collapse show'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav ml-auto' />
              <div className='header-right d-inline'>
                <ul className='d-flex dir align-items-center'>
                  <li className='number'>
                    <i className='fa fa-comment-alt' />
                    <Link className='d-lg-inline-block' to='/contact'>
                      Contact Us
                    </Link>
                  </li>
                  {/* <li className="number">
                    <i className="fa fa-comment-alt" />
                    <a
                      className="d-lg-inline-block"
                      onClick={() => chatWithUs()}
                    >
                      Chat With us
                    </a>
                  </li> */}
                  {/* <li className="number">
                    <i className="fa fa-phone" />
                    <a className="d-lg-inline-block">+1 800-536-0613 </a>
                  </li> */}
                  {!loading && isAuthenticated ? (
                    <>
                      <li>
                        <Link className='header-btn' to='/my-account'>
                          My Account
                        </Link>
                      </li>
                      <li>
                        <a
                          style={{ cursor: 'pointer' }}
                          className='header-btn btn-45 im'
                          onClick={() => logout()}
                        >
                          Logout
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link className='header-btn' to='/login'>
                          Log In
                        </Link>
                      </li>
                      <li>
                        <Link to='/buy-now' className='header-btn btn-45 im'>
                          Buy Now
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Header);

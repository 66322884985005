import React from 'react';
import { connect } from 'react-redux';
import Footer from '../components/template/Footer';
import Header from '../components/template/Header';
export const RefundPolicy = (props) => {
  return (
    <div>
      <Header></Header>
      <section className="terms-area section" style={{ paddingTop: 150 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="section-title-area text-center">
                <h2 className="section-title">Refund Policy</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="terms-wrap">
                <div className="terms-top">
                  <h2 className="terms-title">
                    RETURNS ACCEPTED UNDER CERTAIN CONDITIONS
                  </h2>
                  <p>
                    To be eligible for a return, the activation key must be
                    invalid, there will not be any other exclusion to return
                    policy. Just keep in mind validity of the key can be checked
                    with the support team of the brand.
                  </p>
                  <p>
                    Please provide us with the screenshot of any Error message
                    you have encounter so that we can address to the issue
                  </p>
                  <p>
                    Please check your system requirements before buying the key,
                    you can download trial version to be sure of it.
                  </p>
                  <h2 className="terms-title">REFUNDS (IF APPLICABLE)</h2>
                  <p>
                    If you are approved, then your refund will be processed, and
                    a credit will automatically be applied to your credit card
                    or original method of payment, within a certain amount of
                    days.
                  </p>
                </div>
                <div className="terms-bottom">
                  <h2 className="terms-title">
                    LATE OR MISSING REFUNDS (IF APPLICABLE)
                  </h2>
                  <p>
                    If you haven’t received a refund yet, first check your bank
                    account again.
                  </p>
                  <p>
                    Then contact your credit card company, it may take some time
                    before your refund is officially posted.
                  </p>
                  <p>
                    Next contact your bank. There is often some processing time
                    before a refund is posted.
                  </p>
                  <p>
                    If you’ve done all of this and you still have not received
                    your refund yet, please contact us at{' '}
                    <strong>refund@totalpcscan.com</strong>
                  </p>
                </div>
                <div className="terms-bottom">
                  <h2 className="terms-title">SALE ITEMS (IF APPLICABLE)</h2>
                  <p>Only regular priced items may be refunded.</p>
                </div>
                <div className="terms-bottom">
                  <h2 className="terms-title">EXCHANGES (IF APPLICABLE)</h2>
                  <p>
                    We only replace items if they are defective or damaged. If
                    you need to exchange it for the same item, send us an email
                    at info@totalpcscan.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RefundPolicy);

import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Footer from '../components/template/Footer';
import Header from '../components/template/Header';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { addContact } from '../store/actions/contacts_action';
import { email } from '../shared/email';
import AlertBox from '../components/template/AlertBox';
const ConsumerSupport = ({ addContact }) => {
  return (
    <div>
      <Header></Header>
      <section className='contact-page section' style={{ paddingTop: 200 }}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 offset-lg-3'>
              <div className='section-title-area text-center'>
                <span className='section-sub-title'>Contact Us</span>
                <h2 className='section-title'>Get In Touch</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className='address-area'>
                <div className='single-address'>
                  <div className='media'>
                    <div className='icon mr-4'>
                      <i className='fas fa-map-marker-alt' />
                    </div>
                    <div className='media-body'>
                      <h3 className='mt-0'>Address :</h3>
                      <p>
                        563 Cedar Head Rd Sugarloaf, PA 18249-3507 United States
                      </p>
                    </div>
                  </div>
                </div>

                <div className='single-address'>
                  <div className='media'>
                    <div className='icon mr-4'>
                      <i className='far fa-envelope' />
                    </div>
                    <div className='media-body'>
                      <h3 className='mt-0'>Email :</h3>
                      <p>info@totalpcscan.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-5 offset-lg-1 col-md-6 align-self-center'>
              <div className='contact-form'>
                <h2>Drop Us a Message</h2>
                <Formik
                  initialValues={{
                    email: '',
                    name: '',
                    message: '',
                    phone: '',
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string().email().required('Required'),
                    name: Yup.string().required('Required'),
                    phone: Yup.string().required('Required'),
                    message: Yup.string().required('Required'),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    console.log('Submitted');
                    await addContact(values);
                    const html_message = `<p> Contact Form Enquiry  </p>
                    <p> Name : <strong> ${values.name} </strong> </p>
                    <p> phone : <strong> ${values.phone} </strong> </p>
                    <p> email : <strong> ${values.email} </strong> </p>
                    <p> message : <strong> ${values.message} </strong> </p>
                    `;
                    await email({
                      subject: `Contact Form Enquiry in Total PC Scan from ${values.name}`,
                      message: html_message,
                    });
                    setSubmitting(false);
                    resetForm();
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => {
                    console.log('Submitting', isSubmitting);
                    return (
                      <Form>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <input
                                type='text'
                                className='form-control'
                                name='name'
                                placeholder='Name'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                              {errors.name && touched.name && (
                                <div className='text-danger'>{errors.name}</div>
                              )}
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group'>
                              <input
                                type='email'
                                className='form-control'
                                name='email'
                                placeholder='Email'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              {errors.email && touched.email && (
                                <div className='text-danger'>
                                  {errors.email}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-lg-12'>
                            <div className='form-group'>
                              <input
                                type='text'
                                className='form-control'
                                name='phone'
                                placeholder='Phone'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                              />
                              {errors.phone && touched.phone && (
                                <div className='text-danger'>
                                  {errors.phone}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='col-lg-12'>
                            <div className='form-group'>
                              <textarea
                                className='form-control'
                                id='message'
                                rows={3}
                                name='message'
                                placeholder='Message'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.message}
                              />
                              {errors.message && touched.message && (
                                <div className='text-danger'>
                                  {errors.message}
                                </div>
                              )}
                            </div>
                            <button type='submit' className='primary-btn'>
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AlertBox />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { addContact };

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerSupport);

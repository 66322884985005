import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import Footer from "../components/template/Footer";
import Header from "../components/template/Header";
import { getOrders } from "../store/actions/orders_action";
import { getCustomers } from "../store/actions/customers_action";
import { logout } from "../store/actions/auth";
import { Link } from "react-router-dom";
const MyAccount = ({
  getOrders,
  logout,
  getCustomers,
  auth: { user },
  order: { orders, loading },
  customer: { customers },
}) => {
  useEffect(() => {
    if (user) {
      getOrders({ termField: "user", term: user.uid });
      getCustomers({ termField: "user_id", term: user.uid });
    }
  }, [user]);

  return (
    <div>
      <Header />
      <div className="signin-form-area" style={{ paddingTop: 100 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="form-wrap wrap-two my-5">
                <div className="signin-top d-flex justify-content-between align-items-center">
                  <h3 className="signin-title">Welcome</h3>
                  <a
                    onClick={() => logout()}
                    style={{ cursor: "pointer" }}
                    href="#logout"
                    className="btn btn-danger"
                  >
                    LOGOUT
                  </a>
                </div>
                <hr />

                <div className="account d-flex mt-3 text-center">
                  <div className style={{ flex: 1 }}>
                    <h5>
                      <strong>Name:</strong>
                    </h5>
                  </div>
                  <div className style={{ flex: 1 }}>
                    <h6>
                      {" "}
                      {customers && customers.length > 0 && customers[0].name}
                    </h6>
                  </div>
                </div>
                <div className="account d-flex mt-3 text-center">
                  <div className style={{ flex: 1 }}>
                    <h5>
                      <strong>Email:</strong>
                    </h5>
                  </div>
                  <div className style={{ flex: 1 }}>
                    <h6>{user && user.email}</h6>
                  </div>
                </div>
                <div className="account d-flex mt-3 text-center">
                  <div className style={{ flex: 1 }}>
                    <h5>
                      <strong>Phone:</strong>
                    </h5>
                  </div>
                  <div className style={{ flex: 1 }}>
                    <h6>
                      {customers && customers.length > 0 && customers[0].phone}
                    </h6>
                  </div>
                </div>

                <hr />
                <h3> Previous Orders </h3>
                {orders &&
                  orders.map((item) => {
                    return (
                      <div className="card mb-3">
                        <div className="card-body">
                          <div className="d-flex justify-content-between p-1">
                            <div>Purchase Date</div>
                            <div>
                              <strong>{item.purchased_date} </strong>{" "}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between p-1">
                            <div>Amount</div>
                            <div>
                              <strong>${item.amount} </strong>{" "}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between p-1">
                            <div>Valid Till</div>
                            <div>
                              <strong>{item.validity} </strong>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between p-1">
                            <div>
                              <p>Product Key</p>
                            </div>
                            <div>
                              <h3> {item.product_key} </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {orders && orders.length === 0 && <p> No Orders Found </p>}
                <hr />
                <div className="text-center">
                  <p> Do You want to buy new license </p>
                  <Link to="/buy-now" className="primary-btn im">
                    {" "}
                    Buy Now{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  order: state.order,
  customer: state.customer,
});

const mapDispatchToProps = { getOrders, getCustomers, logout };

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);

import React, { useEffect } from 'react';
import './assets/css/bootstrap.min.css';
import './assets/css/app.min.css';
import './assets/css/custom.css';
import './assets/css/wysiwyg.css';
import './assets/css/responsive.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';

import { loadUser } from './store/actions/auth';
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes';
import PrivateRoutes from './shared/Routes/PrivateRoutes';
import Home from './containers/Home';
import Login from './containers/Login';

import Terms from './containers/Terms';
import Signup from './containers/SignUp';
import Refund from './containers/RefundPolicy';
import Privacy from './containers/PrivacyPolicy';
import FAQ from './containers/FAQ';
import BuyNow from './containers/BuyNow';
import MyAccount from './containers/MyAccount';
import ConsumerSupport from './containers/ConsumerSupport';
import TryNow from './containers/TryNow';
import Checkout from './containers/Checkout';
import EULA from './containers/EULA';
import Uninstall from './containers/Uninstall';
import ForgetPassword from './containers/ForgetPassword';
import ResetPassword from './containers/ResetPassword';

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <PrivateRoutes exact path='/my-account' component={MyAccount} />
          <PrivateRoutes exact path='/checkout' component={Checkout} />
          <Route exact path='/' component={Home} />
          <BeforeLoginRoutes exact path='/login' component={Login} />
          <Route exact path='/terms-and-conditions' component={Terms} />
          <Route exact path='/privacy-policy' component={Privacy} />
          <Route exact path='/buy-now' component={BuyNow} />
          <Route exact path='/faq' component={FAQ} />
          <Route exact path='/refund-policy' component={Refund} />
          <BeforeLoginRoutes exact path='/sign-up' component={Signup} />
          <Route exact path='/try-now' component={TryNow} />
          <Route exact path='/contact' component={ConsumerSupport} />
          <Route exact path='/eula' component={EULA} />
          <Route exact path='/how-to-uninstall' component={Uninstall} />
          <Route exact path='/forgot-password' component={ForgetPassword} />
          <Route exact path='/reset-password' component={ResetPassword} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
